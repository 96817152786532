import brandsHandler from './brands'
import categoryHandler from './category'
import productHandler from './products'
import userHandler from './user'

export const handlers = [ 
    ...productHandler,
    ...brandsHandler,
    ...categoryHandler,
    ...userHandler
]
