import { useQuery } from "@apollo/client"
import { GET_FEATURES_BRANDS } from "../GraphQL/Brands/queries"
import { GetFeaturedBrands, GetFeaturedBrands_featuredBrands } from "../GraphQL/Brands/types"

interface IUseBrandsQuery {
    getFeaturedBrands: GetFeaturedBrands | undefined;
    featuredBrandsError: Error | undefined;
    featuredBrandsLoading: boolean;
}
export const useBrandsQuery = (): IUseBrandsQuery => {
    const { 
        data: getFeaturedBrands,
        loading: featuredBrandsLoading,
        error: featuredBrandsError
    } = useQuery<GetFeaturedBrands, GetFeaturedBrands_featuredBrands>(GET_FEATURES_BRANDS)

    return {
        getFeaturedBrands,
        featuredBrandsError,
        featuredBrandsLoading
    }
}