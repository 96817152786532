import {
  Grid,
  GridItem,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { Images } from "@styled-icons/icomoon";
import { useNavigate } from "react-router-dom";
import ConfirmDeleteDraftModal from "Components/ConfirmDeleteDraftModal";

const Container = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Title = styled.h2`
  color: #000;
  font-size: 1.25rem;
`;

const ImageWrapper = styled.div`
  margin-right: 20px;
`;

const ItemTitle = styled.div`
  font-family: RockfordSansLight;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  min-height: 80px;
`;

const Button = styled.button`
  color: #4724fe;
  margin-right: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Image = styled.img`
  max-width: 70px;
  max-height: 70px;
`;

const ImageContainer = styled.div`
  border: solid 1px #ddd;
  padding: 4px;
  margin-top: 8px;
  height: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const LatestDrafts: FC = () => {
  const drafts = [
    {
      id: "1",
      title:
        "Apple MacBook Pro 13in (256GB SSD, M1, 8GB) Laptop - Space Gray - MGN63LL/A (November, 2020)",
      image: require("Assets/pictures/s-l640.jpg"),
    },
    {
      id: "2",
      title:
        'Apple MacBook Pro 13.3" (1TB SSD, Intel Core i5 10th Gen., 3.80 GHz, 16GB) Laptop',
    },
    {
      id: "3",
      title: "Apple iPhone 8 Plus 64gb Black Verizon Factory Unlocked",
      image: require("Assets/pictures/iphone.jpg"),
    },
  ];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const onResume = (id: string) => {
    navigate(`/draft/${id}`);
  };
  const onDelete = (id: string) => {
    onClose();
  };
  return (
    <Container>
      <Title>Latest drafts</Title>
      <Grid templateColumns={"repeat(3, 1fr)"}>
        {drafts.map((draft) => (
          <GridItem key={draft.id}>
            <ItemRow>
              <ImageWrapper>
                {draft.image && (
                  <ImageContainer>
                    <Image src={draft.image} />
                  </ImageContainer>
                )}
                {!draft.image && (
                  <Images
                    size={60}
                    color="#c4c4c45c"
                    style={{ marginTop: 5 }}
                  />
                )}
              </ImageWrapper>
              <Stack marginRight={"20px"}>
                <ItemTitle>{draft.title}</ItemTitle>
                <Row>
                  <Button onClick={() => onResume(draft.id)}>Resume</Button>
                  <Button onClick={onOpen}>Delete</Button>
                </Row>
                <ConfirmDeleteDraftModal
                  isOpen={isOpen}
                  onClose={onClose}
                  onDelete={() => onDelete(draft.id)}
                />
              </Stack>
            </ItemRow>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
};

export default LatestDrafts;
