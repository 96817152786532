import { graphql } from "msw";

const brands = [
  {
    id: "cl63tpuin000001qxc06x9jin",
    name: "Apple",
    image: require("Assets/pictures/apple.webp"),
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "cl63tu9ou000001mk085ch4ue",
    name: "Sony",
    image: require("Assets/pictures/sony.webp"),
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "cl63tuihn000001pz7wfz3mze",
    name: "Samsung",
    image: require("Assets/pictures/samsung.webp"),
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
];

const brandsHandler = [
  graphql.query("GetFeaturedBrands", (req, res, ctx) => {
    return res(ctx.data({ brands }));
  }),
];

export default brandsHandler;
