import { gql } from "@apollo/client"
import { CATEGORY_FRAGMENT } from "../fragments";
export const GET_FEATURED_CATEGORIES = gql`
  query GetFeaturedCategories {
    categories {
        id
        name
        image
        createdAt
        updatedAt
        }
    }
   `;

export const GET_CATEGORY = gql
  `query GetCategory($id: ID!) {
    category(id: $id) {
        ...category
        }
    }
    ${CATEGORY_FRAGMENT}`;
export const GET_CATEGORIES_BY_NAME = gql`
  query GetCategoriesByName($name: String!) {
    categories(name: $name) {
       ...category
        }
    }
    ${CATEGORY_FRAGMENT}`;

export const GET_CATEGORIES_BY_ID = gql`
  query GetCategoriesById($id: ID!) {
    categories(id: $id) {
       ...category
        }
    }
    ${CATEGORY_FRAGMENT}`;
