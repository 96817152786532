import {
  ButtonProps,
  Button as DefaultButton,
  Flex,
  Input as DefaultInput,
  InputGroup as DefaultInputGroup,
  InputLeftElement as DefaultInputLeftElement,
  InputRightElement as DefaultInputRightElement,
  List,
  ListItem as DefaultListItem,
  Popover,
  PopoverContent as DefaultPopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Search, ChevronDown } from "@styled-icons/evil";
import Menu from "Components/Menu";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShoppingCart from "Components/ShoppingCarts";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import Button from "Components/Button";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
`;

const InputGroup = styled(DefaultInputGroup)`
  width: 700px;
`;

const Input = styled(DefaultInput)`
  height: 48px;
  padding-left: 50px;
  padding-right: 195px;
`;

const InputLeftElement = styled(DefaultInputLeftElement)`
  height: 48px;
  width: 48px;
`;

const InputRightElement = styled(DefaultInputRightElement)`
  height: 48px;
  width: 200px;
`;

const SearchButton = styled(DefaultButton)`
  height: 48px;
  margin-left: 15px;
  width: 168px;
  background-color: #4724fe;
  color: #fff;
`;

const Row = styled.button`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  color: #707081;
`;

const PopoverContent = styled(DefaultPopoverContent)`
  height: 410px;
  top: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(156, 163, 175, 0.2) 0px 20px 25px -5px,
    rgba(156, 163, 175, 0.2) 0px 8px 10px -6px !important;
`;

const ListItem = styled(DefaultListItem)`
  cursor: pointer;
  font-family: RockfordSansLight;
  padding: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  height: 49px;
  display: flex;
  align-items: center;
  &:hover {
    color: #4724fe;
    background-color: #3007ff0d;
  }
`;

const Category = styled.div`
  white-space: nowrap;
  margin-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
`;

const SellAnItemButton = styled(Button)<ButtonProps>`
  color: #4724fe;
  font-family: RockfordSansMedium;
  font-weight: 400;
  width: 112px;
`;

const PageTitle = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  font-family: RockfordSansMedium;
  font-size: 21px;
  text-align: center;
  `;

const SignInButton = styled(SellAnItemButton)<ButtonProps>``;

export type CategoriesProps = {
  active: string;
  categories: any[];
  onSelect: (category: string) => void;
};

const Categories: FC<CategoriesProps> = ({ active, categories, onSelect }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const _onSelect = (category: string) => {
    onSelect(category);
    onClose();
  };
  return (
    <Flex>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Row>
            <Category>{active}</Category>
            <ChevronDown size={28} />
          </Row>
        </PopoverTrigger>
        <PopoverContent>
          <List>
            {categories.map((item, index) => (
              <ListItem key={index} onClick={() => _onSelect(item)}>
                {item}
              </ListItem>
            ))}
          </List>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export type HeaderProps = {
  onSearch: (value: string) => void;
  onlySingIn?: boolean;
  pageTitle?: string;
  carts?: any[];
  counter?: number;
};

const Header: FC<HeaderProps> = ({ onSearch, onlySingIn, pageTitle, counter, carts }) => {
  const [user] = useAuthState(auth);

  const navigate = useNavigate();
  const [active, setActive] = useState("All Categories");
  const categories = [
    "All Categories",
    "Cameras & Photos",
    "Cell Phones & Accessories",
    "Clothing, Shoes & Accessories",
    "Computers/Tablets & Networking",
    "Consumer Electronics",
    "Sporting Goods",
    "Everything Else",
  ];
  const onSelect = (category: string) => {
    setActive(category);
  };
  const onSelectMenu = async (menu: string) => {
    if (menu === "logout") {
      await auth.signOut();
      navigate("/");
      return;
    }
    const routes: Map<string, string> = new Map([
      ["sell", "/sell"],
      ["selling", "/dashboard"],
      ["settings", "/settings"],
    ]);
    navigate(routes.get(menu)!);
  };
  return (
		<Container>
			{!onlySingIn && (
				<>
					<Flex flex={1}></Flex>
					<Flex>
						<Flex flex={1}></Flex>
						<Flex flex={1}></Flex>
						<InputGroup>
							<InputLeftElement
								children={<Search size={32} color="#494848" />}
							/>
							<Input placeholder="Search for anything" />
							<InputRightElement
								children={
									<Categories
										active={active}
										onSelect={onSelect}
										categories={categories}
									/>
								}
							/>
						</InputGroup>
						<SearchButton onClick={() => onSearch("")}>Search</SearchButton>
						<ShoppingCart counter={counter!} carts={carts!} />
						<SellAnItemButton
							label="Sell an item"
							variant={"unstyled"}
							onClick={() => navigate("/sell")}
						/>
						{user && <Menu onSelect={onSelectMenu} />}
						{!user && (
							<SignInButton
								label="Sign in"
								variant={"unstyled"}
								onClick={() => navigate("/signin")}
							/>
						)}
					</Flex>
					<Flex flex={1}></Flex>
				</>
			)}
			{onlySingIn && (
				<>
					<PageTitle>{pageTitle}</PageTitle>
					{user && <Menu onSelect={onSelectMenu} />}
					{!user && (
						<SignInButton
							label="Sign in"
							variant={"unstyled"}
							onClick={() => navigate("/signin")}
						/> 
					)}
				</>
			)}
		</Container>
	);
};

Header.defaultProps = {
  onlySingIn: false,
  carts: [],
  counter: 0,
};

export default Header;
