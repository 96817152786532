import { FC } from "react";
import Header from "Components/Header";
import LeftNavigation from "Components/LeftNavigation";
import styled from "@emotion/styled";
import SummaryTitle from "Components/SummaryTitle";
import Placehorder from "Components/Placeholder";
import { useNavigate } from "react-router-dom";
import ScheduledList from "./ScheduledList";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const ScheduledContainer = styled.div`
  width: 880px;
`;

const RightPane = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Scheduled: FC = () => {
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    navigate(`/search?q=${query}`);
  };
  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <LeftNavigation active="scheduled" />
        <ScheduledContainer>
          <SummaryTitle text="Scheduled" />
          {
            false  && <Placehorder text="You don't have any scheduled items." />
          }
          {
            true && <ScheduledList />
          }
        </ScheduledContainer>
        <RightPane />
      </Content>
    </div>
  );
};

export default Scheduled;
