import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent as DefaultPopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select as DefaultSelect,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useRef } from "react";
import { SearchOutline } from "@styled-icons/evaicons-outline";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Title = styled.div<{ marginBottom?: string }>`
  font-family: RockfordSansMedium;
  margin-bottom: 15px;
  padding: 12px;
  padding-bottom: 0;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`;

const Selected = styled(Box)`
  margin-bottom: 20px;
  padding: 12px;
  padding-top: 0;
  padding-bottom: 0;
`;

const Result = styled(Flex)`
  height: 40px;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  &:hover {
    background-color: rgb(247, 247, 247);
  }
`;

const Select = styled(DefaultSelect)`
  &:focus {
    border: 1px solid rgb(226, 232, 240);
  }
`;

const PopoverContent = styled(DefaultPopoverContent)`
 box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(156, 163, 175, 0.2) 0px 20px 25px -5px,
    rgba(156, 163, 175, 0.2) 0px 8px 10px -6px !important;
`;

export type FilterMenuProps = {
  value: string;
  all: string[];
  onSelect: (item: any) => void;
};

const FilterMenu: FC<FilterMenuProps> = ({ value, all, onSelect }) => {
  const ref = useRef(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const _onSelect = (item: any) => {
    onSelect(item);
    onClose();
  };
  return (
    <Popover
      initialFocusRef={ref}
      placement="bottom-start"
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <PopoverTrigger>
        <Select onMouseDown={(e) => e.preventDefault()}>
          <option value="apple">{value}</option>
        </Select>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader backgroundColor={"#f9f9f9"}>
          <InputGroup>
            <InputLeftElement
              width={"24px"}
              height={"24px"}
              children={<SearchOutline size={24} color="#6e6e6ef8" />}
            />
            <Input
              paddingLeft={"35px"}
              variant={"unstyled"}
              placeholder="Search or enter your own"
              ref={ref}
            />
          </InputGroup>
        </PopoverHeader>
        <PopoverBody padding={0}>
          <Title>Selected</Title>
          <Selected>{value}</Selected>
          <Title style={{ paddingTop: 0 }}>Search results</Title>
          {all.map((item, index) => (
            <Result key={index} onClick={() => _onSelect(item)}>
              {item}
            </Result>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FilterMenu;
