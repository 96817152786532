import { formatAmount } from "Lib/format";
import React, { FC } from "react";
import _ from "lodash";
import styled from "@emotion/styled";
import { ListItem as DefaultListItem } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Cover = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
`;

const ListItem = styled(DefaultListItem)`
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f7f7f7;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Shop = styled.div`
  margin-left: 25px;
`;

const Price = styled.div`
  font-size: 14px;
  color: #494966;
`;

const Quantity = styled.div`
  font-size: 14px;
  color: #494966;
`;

const Middot = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  color: #494966;
`;

const ShopName = styled.div``;

export type CartProps = {
  shops: any;
  item: string;
  currentCart: any;
  quantityTotal: any;
  subtotal: any;
  onClose: () => void;
};

const Cart: FC<CartProps> = ({
  item: key,
  currentCart,
  shops,
  quantityTotal,
  subtotal,
  onClose,
}) => {
  const shopname = _.get(shops, `${key}.name`, "");
  const cover = _.get(shops, `${key}.cover`, "");
  const quantity = _.get(quantityTotal, key, "");

  return (
    <ListItem key={key}>
      <Link to={`/cart/${key}`} onClick={onClose}>
        <Row>
          <Cover src={require("Assets/pictures/mitia-marque.jpg")} alt="" />
          <Shop>
            <ShopName>{shopname}</ShopName>
            <Row>
              {quantity === 1 && <Quantity>{quantity} item</Quantity>}
              {quantity > 1 && <Quantity>{quantity} items</Quantity>}
              <Middot>&middot;</Middot>
              <Price>
                {formatAmount(_.get(subtotal, key, ""))} {"Ar"}
              </Price>
            </Row>
          </Shop>
        </Row>
      </Link>
    </ListItem>
  );
};

export default Cart;
