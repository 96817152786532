import styled from "@emotion/styled";
import { FC } from "react";
import Search from "Components/Search";
import { ChevronDown } from "@styled-icons/evil";
import {
  Button as DefaultButton,
  Flex,
  List,
  ListItem as DefaultListItem,
  Popover,
  PopoverContent as DefaultPopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Button = styled(DefaultButton)`
  height: 48px;
  width: 150px;
  margin-left: 15px;
  font-weight: normal;
`;

const PopoverContent = styled(DefaultPopoverContent)`
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(156, 163, 175, 0.2) 0px 20px 25px -5px,
    rgba(156, 163, 175, 0.2) 0px 8px 10px -6px !important;
`;

const ListItem = styled(DefaultListItem)`
  cursor: pointer;
  font-family: RockfordSansLight;
  padding: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  height: 49px;
  display: flex;
  align-items: center;
  &:hover {
    color: #4724fe;
    background-color: #3007ff0d;
  }
`;

const SearchShipping: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const _onSelectFilter = () => {
    onClose();
  };
  return (
    <Row>
      <Flex flex={1}>
        <Search placeholder="Search by order number, tracking number or buyer username" />
      </Flex>
      <Flex>
        <Popover
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          placement="bottom-start"
        >
          <PopoverTrigger>
            <Button>
              <div>Last 90 days</div>
              <ChevronDown size={28} />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <List>
              <ListItem onClick={() => _onSelectFilter()}>
                Last 24 hours
              </ListItem>
              <ListItem onClick={() => _onSelectFilter()}>Last 7 days</ListItem>
              <ListItem onClick={() => _onSelectFilter()}>
                Last 31 days
              </ListItem>
              <ListItem onClick={() => _onSelectFilter()}>
                Current week
              </ListItem>
              <ListItem onClick={() => _onSelectFilter()}>Last week</ListItem>
              <ListItem onClick={() => _onSelectFilter()}>
                Current month
              </ListItem>
              <ListItem onClick={() => _onSelectFilter()}>Last month</ListItem>
              <ListItem onClick={() => _onSelectFilter()}>
                Last 60 days
              </ListItem>
              <ListItem onClick={() => _onSelectFilter()}>
                Last 90 days
              </ListItem>
            </List>
          </PopoverContent>
        </Popover>
      </Flex>
    </Row>
  );
};

export default SearchShipping;
