import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const LIST_DATA = ['sausage'];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  addPaymentMethod: ['paymentMethod'],
  getPaymentMethod: ['id'],
  getAllPaymentMethods: null,
  paymentMethodReceived: ['paymentMethod'],
  paymentMethodsReceived: ['paymentMethods'],
  confirmPayment: ['paymentMethod'],
});

export const PaymentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  searchTerm: '',
  searching: false,
  results: LIST_DATA,
});

/* ------------- Reducers ------------- */

export const paymentMethodReceived = () => INITIAL_STATE;

export const paymentMethodsReceived = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PAYMENT_METHOD_RECEIVED]: paymentMethodReceived,
  [Types.PAYMENT_METHODS_RECEIVED]: paymentMethodsReceived,
});
