import styled from "@emotion/styled";
import { FC } from "react";
import Condition from "../Condition";
import Description from "../Description";
import ItemCategory from "../ItemCategory";
import ItemSpecifics from "../ItemSpecifics";
import ItemTitle from "../ItemTitle";

const Container = styled.div`
  min-height: 288px;
  padding: 25px;
  background-color: #fff;
  margin-bottom: 20px;
`;

export type ListingDetailsProps = {
  itemSpecifics: {
    required: any[];
    additional: any[];
  };
};

const ListingDetails: FC<ListingDetailsProps> = ({ itemSpecifics }) => {
  return (
    <Container>
      <ItemTitle />
      <ItemCategory />
      <ItemSpecifics itemSpecifics={itemSpecifics} />
      {
        // <Condition />
      }
      <Description />
    </Container>
  );
};

export default ListingDetails;
