import Active from "Containers/Active";
import Browse from "Containers/Browse";
import CreateListing from "Containers/CreateListing";
import Draft from "Containers/Drafts";
import Item from "Containers/Item";
import Payments from "Containers/Payments";
import Scheduled from "Containers/Scheduled";
import Sell from "Containers/Sell";
import ShippingLabels from "Containers/ShippingLabels";
import Sold from "Containers/Sold";
import Unsold from "Containers/Unsold";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "Containers/Home";
import SignIn from "Containers/SignIn";
import Settings from "Containers/Settings";
import Selling from "Containers/Selling";
import Orders from "Containers/Orders";
import Cart from "./Containers/Cart";
import CartDetails from "./Containers/Cart/CartDetails";
import Checkout from "./Containers/Checkout";
import Featured from "Containers/Featured";
import Brands from "Containers/Brands";
import Brand from "Containers/Brand";
import Category from "Containers/Category";
import Order from "Containers/Order";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/active" element={<Active />} />
        <Route path="/drafts" element={<Draft />} />
        <Route path="/scheduled" element={<Scheduled />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/sold" element={<Sold />} />
        <Route path="/unsold" element={<Unsold />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/shipping" element={<ShippingLabels />} />
        <Route path="/create" element={<CreateListing />} />
        <Route path="/draft/:id" element={<CreateListing />} />
        <Route path="/edit/:id" element={<CreateListing />} />
        <Route path="/search" element={<Browse />} />
        <Route path="/categories" element={<Browse />} />
        <Route path="/categories/:id" element={<Category />} />
        <Route path="/item/:id" element={<Item />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/dashboard" element={<Selling />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/:id" element={<Order />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/cart/:id" element={<CartDetails />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/featured" element={<Featured />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/brands/:id" element={<Brand />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
