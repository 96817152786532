import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { FC, useState } from "react";
import { Images } from "@styled-icons/icomoon";
import styled from "@emotion/styled";
import { Checkbox } from "baseui/checkbox";
import { Box } from "@chakra-ui/react";
import { CloseOutline } from "@styled-icons/evaicons-outline";
import Filter from "Components/Filter";

const Container = styled.div``;

const ImageContainer = styled.div`
  border: solid 1px #ddd;
  padding: 4px;
  height: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Image = styled.img`
  max-width: 70px;
  max-height: 70px;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Filters = styled(Row)`
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Clear = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(106, 115, 131);
  &:hover {
    color: #000;
  }
`;

const ImageWrapper = styled.div``;

export type UnsoldListProps = {};

const UnsoldList: FC<UnsoldListProps> = () => {
  const [data, setData] = useState([
    {
      id: 1,
      photo: require("Assets/pictures/s-l640.jpg"),
      title: "Apple MacBook Air 13in (256GB SSD, M1, 8GB) ",
      price: "6,000,000 Ar",
      quantity: "1",
      views: 120,
      selected: false,
    },
  ]);
  const hasAny = Boolean(data.length);
  const hasAll = hasAny && data.every((x) => x.selected);
  const hasSome = hasAny && data.some((x) => x.selected);

  function toggleAll(event: any) {
    setData((data) =>
      data.map((row) => ({
        ...row,
        selected: !hasAll,
      }))
    );
  }

  function toggle(event: any) {
    const { name, checked } = event.currentTarget;
    setData((data) =>
      data.map((row) => ({
        ...row,
        selected: String(row.id) === name ? checked : row.selected,
      }))
    );
  }
  return (
    <Container>
      <Filters>
        <Row>
          <Filter label="Title" onApply={() => {}} options={["contains"]} />
          <Filter
            label="Price"
            onApply={() => {}}
            options={[
              "is equal to",
              "is between",
              "is greater than",
              "is less than",
            ]}
          />
          <Filter
            label="Quantity"
            onApply={() => {}}
            options={[
              "is equal to",
              "is between",
              "is greater than",
              "is less than",
            ]}
          />
          <Filter
            label="Views"
            onApply={() => {}}
            options={[
              "is equal to",
              "is between",
              "is greater than",
              "is less than",
            ]}
          />
          <Filter
            label="Item specifics"
            onApply={() => {}}
            options={[
              "Year",
            ]}
            multi
          />
        </Row>
        <Clear>
          <IconWrapper>
            <CloseOutline size={20} />
          </IconWrapper>
          <Box fontSize={14} marginTop={"-2px"}>
            Clear Filters
          </Box>
        </Clear>
      </Filters>
      <TableBuilder data={data}>
        <TableBuilderColumn
          overrides={{
            TableHeadCell: { style: { width: "1%" } },
            TableBodyCell: { style: { width: "1%" } },
          }}
          header={
            <Checkbox
              checked={hasAll}
              isIndeterminate={!hasAll && hasSome}
              onChange={toggleAll}
              overrides={styles.checkmark}
            />
          }
        >
          {(row) => (
            <Checkbox
              name={row.id}
              checked={row.selected}
              onChange={toggle}
              overrides={styles.checkmark}
            />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn header="Photo">
          {(row) => (
            <>
              <ImageWrapper>
                {row.photo && (
                  <ImageContainer>
                    <Image src={row.photo} />
                  </ImageContainer>
                )}
                {!row.photo && <Images size={60} color="#c4c4c45c" />}
              </ImageWrapper>
            </>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn header="Title">
          {(row) => <div>{row.title}</div>}
        </TableBuilderColumn>
        <TableBuilderColumn header="Current Price">
          {(row) => <div>{row.price}</div>}
        </TableBuilderColumn>
        <TableBuilderColumn header="Available Quantity">
          {(row) => <div>{row.quantity}</div>}
        </TableBuilderColumn>
        <TableBuilderColumn header="Views">
          {(row) => <div>{row.views}</div>}
        </TableBuilderColumn>
      </TableBuilder>
    </Container>
  );
};

const styles = {
  checkmark: {
    Checkmark: {
      style: ({ $checked, $theme }: any) => ({
        borderLeftColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray400,
        borderRightColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray400,
        borderTopColor: $checked ? $theme.colors.accent : $theme.colors.gray400,
        borderBottomColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray400,
        backgroundColor: $checked ? $theme.colors.accent : null,
      }),
    },
  },
};

export default UnsoldList;
