import styled from "@emotion/styled";
import { FC } from "react";
import { Button as DefaultButton } from "@chakra-ui/react";
import { css } from "@emotion/react";

const StyledButton = styled(DefaultButton)<{
  primary?: boolean;
  secondary?: boolean;
  textColor?: string;
  width?: string;
  height?: string;
}>`
  height: 48px;
  width: 168px;
  ${({ textColor }) => textColor && css`color: ${textColor}`};
  ${({ primary }) =>
    primary &&
    css`
      background-color: #4724fe;
      color: #fff;
      &:hover {
        background-color: #4724fe;
      }
    `}
  ${({ secondary }) =>
    secondary &&
    css`
      background-color: #23fdd8;
      color: #2c398f;
      &:hover {
        background-color: #23fdd965;
      }
    `}
  ${({ width }) => width && css`width: ${width}`};
  ${({ height }) => height && css`height: ${height}`};
`;

export type ButtonProps = {
  label: string;
  textColor?: string;
  rounded?: string;
  primary?: boolean;
  secondary?: boolean;
  width?: string;
  height?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

const Button: FC<ButtonProps> = (props) => {
  return (
    <StyledButton {...props}>
      {props.label}
      {props.children}
    </StyledButton>
  );
};

export default Button;
