import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const LIST_DATA = [
  {
    id: 1,
    date: '2020-01-01',
    total: '10,000.00',
    currency: 'Ar',
    status: 'Pending',
    orderId: 'PEB55KWLP9',
    items: [{}],
    image: require('Images/281709738_1706863956326737_2310605836636155947_n.jpg'),
  },
  {
    id: 2,
    date: '2020-01-02',
    total: '30,000.00',
    currency: 'Ar',
    status: 'Pending',
    orderId: 'WG4PDVO4BJ',
    items: [{}],
    image: require('Images/281723276_1706864496326683_478838822581273716_n.jpg'),
  },
];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getOrder: ['id'],
  getAllOrders: null,
  orderReceived: ['order'],
  ordersReceived: ['orders'],
});

export const OrderTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: LIST_DATA,
});

/* ------------- Reducers ------------- */

export const orderReceived = () => INITIAL_STATE;

export const ordersReceived = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ORDER_RECEIVED]: orderReceived,
  [Types.ORDERS_RECEIVED]: ordersReceived,
});
