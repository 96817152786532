import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const LIST_DATA = ['sausage'];

const SHOPS = [
  {
    id: '1',
    name: 'Mitia Mode',
    cover: require('Images/242231287_1541365739543227_5880412979109259294_n.jpg'),
  },
  {
    id: '2',
    name: 'Mitia Marque',
    cover: require('Images/81542968_2616212998616342_1631923358700404736_n.jpg'),
  },
];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getShop: ['id'],
  getAllShops: null,
  shopsReceived: ['shops'],
  shopReceived: ['shop'],
});

export const ShopTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  results: LIST_DATA,
  shops: SHOPS,
});

/* ------------- Reducers ------------- */

export const shopReceived = () => INITIAL_STATE;

export const shopsReceived = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SHOP_RECEIVED]: shopReceived,
  [Types.SHOPS_RECEIVED]: shopsReceived,
});
