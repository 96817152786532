import { combineReducers } from "redux";
import configureStore from "./CreateStore";
import rootSaga from "../Sagas";

export const reducers = combineReducers({
  address: require("./AddressRedux").reducer,
  brands: require("./BrandRedux").reducer,
  cart: require("./CartRedux").reducer,
  category: require("./CategoryRedux").reducer,
  order: require("./OrderRedux").reducer,
  payment: require("./PaymentRedux").reducer,
  promotion: require("./PromotionRedux").reducer,
  product: require("./ProductRedux").reducer,
  search: require("./SearchRedux").reducer,
  shop: require("./ShopRedux").reducer,
  user: require("./UserRedux").reducer,
});

export default function createStore() {
  let { store } = configureStore(reducers, rootSaga);

  return store;
}
