import { FC } from "react";
import Header from "Components/Header";
import LeftNavigation from "Components/LeftNavigation";
import Overview from "Components/Overview";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const Selling: FC = () => {
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    if (query === '') {
      navigate('/categories');
      return;
    }
    navigate(`/search?q=${query}`);
  };
  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <LeftNavigation active="overview" />
        <Overview onListAnItem={() => navigate("/sell")} />
        <Flex flex={1} />
      </Content>
    </div>
  );
};

export default Selling;
