import styled from "@emotion/styled";
import { FC } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverContent as DefaultPopoverContent,
  PopoverTrigger,
  List,
  ListItem,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { ChevronRightOutline } from "@styled-icons/evaicons-outline";

const Link = styled.button`
  color: #4724fe;
  cursor: pointer;
  margin-top: 10px;
  width: fit-content;
`;

const Category = styled.button`
  font-family: RockfordSansLight;
  font-size: 14px;
  padding: 6px 8px;
`;

const PopoverContent = styled(DefaultPopoverContent)`
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(156, 163, 175, 0.2) 0px 20px 25px -5px,
    rgba(156, 163, 175, 0.2) 0px 8px 10px -6px !important;
`;

export type BrowseCategoriesProps = {
  categories: any[];
  subcategories: any[];
  onSelect: (category: string) => void;
};

const BrowseCategories: FC<BrowseCategoriesProps> = ({
  categories,
  subcategories,
}) => {
  return (
    <>
      <Popover placement="bottom-start">
        <PopoverTrigger>
          <Link>Browse categories</Link>
        </PopoverTrigger>
        <PopoverContent style={styles.popover}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Grid templateColumns={"repeat(3, 1fr)"}>
              <GridItem>
                <List overflow={"auto"} height="263px">
                  {categories.map((category) => (
                    <ListItem key={category.id}>
                      <Category>{category.name}</Category>
                      <ChevronRightOutline size={24} />
                    </ListItem>
                  ))}
                </List>
              </GridItem>
              <Grid templateColumns={"repeat(3, 1fr)"}>
                <GridItem>
                  <List overflow={"auto"} height="263px">
                    {subcategories.map((category) => (
                      <ListItem key={category.id}>
                        <Category>{category.name}</Category>
                        <ChevronRightOutline size={24} />
                      </ListItem>
                    ))}
                  </List>
                </GridItem>
              </Grid>
              <Grid templateColumns={"repeat(3, 1fr)"}></Grid>
            </Grid>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

const styles = {
  popover: {
    width: 1200,
    height: 279,
  },
};

export default BrowseCategories;
