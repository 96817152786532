import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader as DefaultModalHeader,
  ModalCloseButton,
  ModalBody as DefaultModalBody,
  ModalFooter,
  ButtonProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import DefaultButton from "Components/Button";

const ModalHeader = styled(DefaultModalHeader)`
  font-family: RockfordSansMedium;
  font-weight: normal;
  padding-bottom: 0px;
`;

const ModalBody = styled(DefaultModalBody)`
  margin: 16px 24px 24px;
  padding: 0px;
  font-family: RockfordSansLight;
`;

const CancelButton = styled(DefaultButton)<ButtonProps>`
  color: #4724fe;
`;

const DeleteButton = styled(DefaultButton)<ButtonProps>``;

export type ConfirmDeleteSelectedDraftsProps = {
  onDelete: () => void;
  onClose: () => void;
  isOpen: boolean;
  selected: number;
};

const ConfirmDeleteSelectedDraftsModal: FC<ConfirmDeleteSelectedDraftsProps> = ({
  isOpen,
  onClose,
  onDelete,
  selected,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete {selected > 1 ? `${selected} drafts` : 'draft'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete {selected > 1 ? 'these drafts' : 'this draft'} ?</ModalBody>
        <ModalFooter>
          <CancelButton label="Cancel" onClick={onClose} variant="unstyled" />
          <DeleteButton
            label="Delete"
            primary
            rounded={"full"}
            onClick={onDelete}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmDeleteSelectedDraftsModal;
