import {
  Button,
  Input as DefaultInput,
  Modal,
  ModalBody as DefaultModalBody,
  ModalCloseButton,
  ModalContent as DefaultModalContent,
  ModalHeader as DefaultModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, useState } from "react";

const ModalHeader = styled(DefaultModalHeader)`
  font-family: RockfordSansMedium;
  font-weight: normal;
  text-align: center;
  margin-right: 40px;
`;

const ModalContent = styled(DefaultModalContent)`
  height: 87%;
  overflow-y: auto;
  padding: 16px 32px 0;
  padding-top: 0;
`;

const Input = styled(DefaultInput)`
  background-color: #f9f9f9;
  &:focus {
    background-color: #fff;
  }
`;

const Tips = styled.div<{ marginBottom?: string }>`
  font-family: RockfordSansLight;
  font-size: 15px;
  color: gray;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `};
`;

const Label = styled.div`
  font-family: RockfordSansLight;
  font-size: 15px;
`;

const SubText = styled(Label)`
  color: gray;
  margin-bottom: 16px;
  margin-inline-start: 10px !important;
  margin-top: 4px !important;
`;

const CloseButton = styled(Button)`
  height: 40px;
  width: 128px;
  font-family: RockfordSansLight;
  color: #4724fe;
`;

const SaveButton = styled(Button)`
  height: 40px;
  width: 128px;
  font-family: RockfordSansLight;
  background-color: #4724fe;
  color: #fff;
`;

const Flex = styled.div`
  display: flex;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const ModalBody = styled(DefaultModalBody)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export type AddCustomItemSpecificModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string, value: string) => void;
};

const AddCustomItemSpecificModal: FC<AddCustomItemSpecificModalProps> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add custom item specific</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tips marginBottom="32px">
            Type in a unique detail that your buyers might look for.
          </Tips>
          <Flex>
            <Stack flex={1}>
              <Label>Name</Label>
              <Input />
              <SubText>Example: Year</SubText>
              <Label>Value</Label>
              <Input />
              <SubText>Example: 2022</SubText>
            </Stack>
          </Flex>
          <Row>
            <CloseButton variant={"unstyled"} onClick={onClose}>
              Close
            </CloseButton>
            <SaveButton onClick={() => onSave(name, value)}>Save</SaveButton>
          </Row>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCustomItemSpecificModal;
