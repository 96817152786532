import { useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Images } from "@styled-icons/icomoon";
import { Checkbox } from "baseui/checkbox";
import ConfirmDeleteDraftModal from "Components/ConfirmDeleteDraftModal";
import { FC } from "react";

const ItemDetails = styled.div`
  padding-left: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  height: 120px;
  margin-right: 15px;
`;

const ItemTitle = styled.a`
  font-family: RockfordSansLight;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 120px;
`;

const Button = styled.button`
  color: #4724fe;
  margin-right: 10px;
`;

const Separator = styled.div`
  background-color: #dadada;
  width: 2px;
  height: 18px;
  margin-right: 10px;
  margin-top: 5px;
`;

export type DraftProps = {
  item: {
    id: string;
    title: string;
    image?: string;
  };
  onClick: (id: string) => void;
  selected: boolean;
  setChecked: (id: string, checked: boolean) => void;
};

const Row = styled.div`
  height: 176px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  margin-right: 15px;
`;

const Image = styled.img`
  max-width: 111px;
  max-height: 111px;
`;

const ImageContainer = styled.a`
  border: solid 1px #ddd;
  padding: 4px;
  height: 120px;
  width: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Draft: FC<DraftProps> = ({ item, onClick, selected, setChecked }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onDelete = (id: string) => {
    onClose();
  };

  return (
    <Row>
      <CheckboxContainer>
        <Checkbox
          checked={selected}
          onChange={(e) => setChecked(item.id, (e.target as any).checked)}
          overrides={styles.checkmark}
        />
      </CheckboxContainer>
      <ImageContainer href={`/draft/${item.id}`}>
        {item.image && <Image src={item.image} />}
        {!item.image && (
          <Images size={60} color="#c4c4c45c" style={{ marginTop: 5 }} />
        )}
      </ImageContainer>
      <ItemDetails>
        <ItemTitle href={`/draft/${item.id}`}>{item.title}</ItemTitle>
      </ItemDetails>
      <ButtonWrapper>
        <Button onClick={() => onClick(item.id)}>Resume draft</Button>
        <Separator />
        <Button onClick={onOpen}>Delete</Button>
      </ButtonWrapper>
      <ConfirmDeleteDraftModal
        isOpen={isOpen}
        onClose={onClose}
        onDelete={() => onDelete(item.id)}
      />
    </Row>
  );
};

const styles = {
  checkmark: {
    Checkmark: {
      style: ({ $checked, $theme }: any) => ({
        borderLeftColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray500,
        borderRightColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray500,
        borderTopColor: $checked ? $theme.colors.accent : $theme.colors.gray500,
        borderBottomColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray500,
        backgroundColor: $checked ? $theme.colors.accent : null,
      }),
    },
  },
};

export default Draft;
