import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { ChevronRight } from "@styled-icons/bootstrap/ChevronRight";

const Seller = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 20px 12px 20px 12px;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

const Label = styled.div`
  font-family: RockfordSansLight;
  font-size: 1rem;
`;

const SellerImageWrapper = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const SellerInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const SellerName = styled.div`
  font-family: RockfordSansMedium;
  font-size: 1.2rem;
`;

const SimpleRow = styled.div`
  display: flex;
`;

const SimpleRowSpaced = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const Middot = styled.div`
  margin: 0 0.5rem;
  font-size: 1rem;
  font-family: RockfordSansBold;
`;
interface SellerCartProps {
  onClickSeller: () => void;
}

const SellerCart: FC<SellerCartProps> = ({ onClickSeller }) => {
  return (
    <Seller onClick={onClickSeller}>
      <SellerImageWrapper src={require("../../Assets/pictures/samsung.png")} />
      <SimpleRowSpaced>
        <SellerInformation>
          <SellerName>Uditech</SellerName>
          <SimpleRow>
            <Label>1 item</Label>
            <Middot>&middot;</Middot>
            <Label>250,000 Ar</Label>
          </SimpleRow>
          <Label>Deliver to Ambatobe</Label>
        </SellerInformation>
        <ChevronRight size={32} />
      </SimpleRowSpaced>
    </Seller>
  );
};

export default SellerCart;
