import { Box, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "Components/Button";
import Footer from "Components/Footer";
import Header from "Components/Header";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useProductsQuery } from "Hooks/useProducts";
import SellerInformation from "./SellerInformation";
import CartActions from "Redux/CartRedux";

interface RouteParams {
  id: string;
}

const Image = styled.img`
  max-height: 500px;
  max-width: 700px;
`;

const Wrapper = styled.div`
  padding-top: 50px;
  width: 1500px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-family: RockfordSansLight;
  margin-right: 10px;
  width: 150px;
  text-align: right;
`;

const Text = styled.div<{ bold?: boolean; centered?: boolean }>`
  font-family: RockfordSansLight;
  ${({ bold }) =>
    bold &&
    css`
      font-family: RockfordSansMedium;
    `};
  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `};
`;

const ItemDetails = styled.div`
  width: 700px;
`;

const ImageWrapper = styled.div`
  height: 500px;
  width: 500px;
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e6e6;

  border-radius: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Line = styled(Row)`
  height: 40px;
  align-items: center;
`;

const Price = styled(Text)`
  font-size: 24px;
  width: 200px;
`;

const Stat = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  height: 150px;
`;

const Numbers = styled.div`
  font-size: 1.5rem;
  text-align: center;
  color: #4724fe;
  width: 80px;
`;

const Separator = styled.div`
  border: 1px solid #e2e2e27b;
  height: 40px;
`;

const MVola = styled.img`
  width: 60px;
`;

const RoundedButton = styled.button`
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  background-color: #e0dddd7b;
  font-size: 24px;
`;

const ItemCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px;
  margin: 4px;
  margin-left: 15px;
`;

const Quantity = styled(Text)`
  width: 180px;
`;

export type PreviewListingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  item: any;
};

const Item: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<keyof RouteParams>() as RouteParams;
  const { getProduct } = useProductsQuery(id);
  const cart = useSelector((state: any) => state.cart);
  const currentCart = useSelector((state: any) => state.cart.currentCart);

  const item = {
    id: "7tTjHk99hCNNl6k4",
    name: "NodeJS Icon Zipped Hoodie",
    description: "",
    price: 110000,
    currency: "Ar",
    image: "/static/media/nodejs.e39d7698b72cfbfd9825.webp",
    shop: {
      id: "1",
      name: "Mitia Mode",
      cover:
        "/static/media/242231287_1541365739543227_5880412979109259294_n.5412e7d2170a0aa72844.jpg",
    },
  };

  const isInCart = _.get(currentCart, item.shop.id, [])
    .map((x: { id: any }) => x.id)
    .includes(item.id);

  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState<number>(
    _.get(cart, `quantity.${item.id}`, 1) || 1
  );

  const incrementQuantity = () => setQuantity(quantity + 1);
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const buyItNow = () => {
    dispatch(CartActions.resetCart());
    dispatch(CartActions.addItemToCart(item, quantity));
    navigate(`/cart/${item.shop.id}`);
    /*
    navigation.push('Checkout', {
      shopId: item.shop.id,
    });
    */
  };

  const addToCart = () => {
    dispatch(CartActions.addItemToCart(item, quantity));
    // navigation.goBack();
  };

  const updateCart = () => {
    dispatch(CartActions.updateCart(item, quantity));
    // navigation.goBack();
  };

  const removeItem = () => {
    dispatch(CartActions.removeItemFromCart(item));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSearch = (query: string) => {
    if (query === "") {
      navigate("/categories");
      return;
    }
    navigate(`/search?q=${query}`);
  };

  return (
    <>
      <Header onSearch={onSearch} />
      <Wrapper>
        <Row>
          <ImageWrapper>
            <Image src={getProduct?.product?.image} />
          </ImageWrapper>
          <ItemDetails>
            <Title>{getProduct?.product?.name}</Title>
            <Line>
              <Label>Condition:</Label>
              <Text bold>{getProduct?.product?.condition}</Text>
            </Line>
            <Line>
              <Label>Quantity:</Label>
              <Quantity>{getProduct?.product?.quantityAvailable}</Quantity>
              <CenteredRow>
                <ItemCounter>
                  <RoundedButton onClick={decrementQuantity}>-</RoundedButton>
                  <Numbers>{quantity}</Numbers>
                  <RoundedButton onClick={incrementQuantity}>+</RoundedButton>
                </ItemCounter>
              </CenteredRow>
            </Line>
            <Row>
              <Line>
                <Label>Price:</Label>
                <Price bold>{getProduct?.product?.price}</Price>
              </Line>
              {!isInCart && (
                <Flex flex={1} flexDirection="column">
                  <Box marginBottom={"12px"} marginTop={"20px"}>
                    <Button
                      label="Buy It Now"
                      secondary
                      width="100%"
                      height="50px"
                      onClick={buyItNow}
                    />
                  </Box>
                  <Box>
                    <Button
                      label="Add to cart"
                      textColor="#2c398f"
                      width="100%"
                      height="50px"
                      onClick={addToCart}
                    />
                  </Box>
                </Flex>
              )}
              {isInCart && (
                <Flex flex={1} flexDirection="column">
                  <Box marginBottom={"12px"} marginTop={"20px"}>
                    <Button
                      label="Update Cart"
                      secondary
                      width="100%"
                      height="50px"
                      onClick={updateCart}
                    />
                  </Box>
                  <Box>
                    <Button
                      label="Remove item"
                      textColor="#2c398f"
                      width="100%"
                      height="50px"
                      onClick={removeItem}
                    />
                  </Box>
                </Flex>
              )}
            </Row>
            <Stat>
              <Flex
                flex={1}
                flexDirection={"column"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Numbers>{getProduct?.product?.quantitySold}</Numbers>
                <Text bold centered>
                  Sold
                </Text>
              </Flex>
              <Separator />
              <Flex
                flexDirection={"column"}
                width={"322px"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Numbers>{getProduct?.product?.numberOfWatchers}</Numbers>
                <Text bold centered>
                  Watchers
                </Text>
              </Flex>
            </Stat>
            <Line>
              <Label>Payments:</Label>
              <MVola src={require("Assets/pictures/mvola.png")} />
            </Line>
          </ItemDetails>
          <SellerInformation id={getProduct?.product?.seller?.id || ""} />
        </Row>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Item;
