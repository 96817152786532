import { Button, Input as DefaultInput } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Google from "../../Components/Icons/Google";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Facebook from "../../Components/Icons/Facebook";

const Input = styled(DefaultInput)`
  height: 48px;
  margin-bottom: 20px;
`;

const GoogleSignInButton = styled(Button)`
  font-weight: 500;
  display: flex;
  flex-direction: row;
  border: 1px solid #dcdcdc;
  width: 420px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 15px ;
`;

const FacebookSignInButton = styled(Button)`
  font-weight: 500;
  display: flex;
  flex-direction: row;
  width: 420px;
  height: 48px;
  justify-content: center;
  align-items: center;
  background-color: #1877f2;
  color: #fff;
`;

const Continue = styled(Button)`
  font-weight: 500;
  display: flex;
  background-color: #4724fe;
  color: #fff;
  width: 420px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 420px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

const Separator = styled(Row)`
  height: 30px;
`;

const Link = styled.a`
  font-weight: 500;
  color: #4724fe;
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  margin: 0 auto;
`;

const ButtonText = styled.div`
  flex:1;
`;

const SignIn: FC = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;
    if (user) return navigate("/");
  }, [user, loading]);

  const onSignInWithGoogle = async () => {

    try {
      const res = await signInWithPopup(auth, googleProvider);
      console.log(res);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <Container>
      <Wrapper>
        <Input placeholder="Email or username" />
        <Continue onClick={() => {}} variant={"unstyled"}>
          Continue
        </Continue>
        <Separator>
          <div>or</div>
        </Separator>
        <GoogleSignInButton onClick={onSignInWithGoogle} variant={"unstyled"}>
          <Google />
          <ButtonText>Sign in with Google</ButtonText>
        </GoogleSignInButton>
        <FacebookSignInButton>
          <Facebook />
          <ButtonText>Sign in with Facebook</ButtonText>
        </FacebookSignInButton>
        <Row>
          <div>Don't have account? &nbsp;</div>
          <Link href="/signup"> Sign up</Link>
        </Row>
      </Wrapper>
    </Container>
  );
};

export default SignIn;
