import { ButtonGroupProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Checkbox } from "baseui/checkbox";
import ConfirmDeleteSelectedDraftsModal from "Components/ConfirmDeleteSelectedDraftsModal";
import DefaultButton from "Components/Button";
import { FC } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
`;

const CheckboxContainer = styled.div`
  margin-right: 15px;
`;

const Text = styled.div`
  font-family: RockfordSansLight;
  font-size: 15px;
`;

const DeleteButton = styled(DefaultButton)<ButtonGroupProps>`
  margin-left: 15px;
  height: 40px;
`;

export type ActionProps = {
  selectAll: boolean;
  checkStatuses: { [key: string]: boolean };
  onSelectOrDeselectAll: (checked: boolean) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onDeleteSelected: () => void;
};

const Action: FC<ActionProps> = ({
  selectAll,
  checkStatuses,
  onSelectOrDeselectAll,
  isOpen,
  onOpen,
  onClose,
  onDeleteSelected,
}) => {
  return (
    <Container>
      <CheckboxContainer>
        <Checkbox
          checked={selectAll}
          onChange={(e) => onSelectOrDeselectAll((e.target as any).checked)}
          overrides={styles.checkmark}
        />
      </CheckboxContainer>
      <Text>Select all items</Text>
      {(selectAll || Object.values(checkStatuses).includes(true)) && (
        <DeleteButton
          onClick={onOpen}
          label="Delete"
          rounded={"full"}
          secondary
        />
      )}
      <ConfirmDeleteSelectedDraftsModal
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onDeleteSelected}
        selected={Object.values(checkStatuses).filter((v) => v).length}
      />
    </Container>
  );
};

const styles = {
  checkmark: {
    Checkmark: {
      style: ({ $checked, $theme }: any) => ({
        borderLeftColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray500,
        borderRightColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray500,
        borderTopColor: $checked ? $theme.colors.accent : $theme.colors.gray500,
        borderBottomColor: $checked
          ? $theme.colors.accent
          : $theme.colors.gray500,
        backgroundColor: $checked ? $theme.colors.accent : null,
      }),
    },
  },
};

export default Action;
