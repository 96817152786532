import { FC, useState } from "react";
import styled from "@emotion/styled";
import Header from "../../../Components/Header";
import { useNavigate } from "react-router-dom";
import { Box, Container, Divider } from "@chakra-ui/react";
import Button from "../../../Components/Button";
import { Link as DefaultLink, ButtonProps } from "@chakra-ui/react";
import CartItem from "./CartItem";
import { formatAmount } from "Lib/format";
import Footer from "Components/Footer";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-family: RockfordSansMedium;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const CardsContainer = styled.div`
  display: flex;
`;

const Seller = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Checkout = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;

  margin: 12px;
  min-width: 300px;
  padding: 12px;
  flex-shrink: 0;
`;

const ProductPrice = styled.div`
  display: flex;
  flex-shrink: 2;
  font-size: 20px;
  align-self: center;
  font-family: RockfordSansMedium;
`;

const Label = styled.div`
  font-family: RockfordSansLight;
  margin-right: 4px;
`;

const SellerNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Sellers = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 12px;
  padding: 12px;
  padding-left: 0;
  margin-left: 0;
`;

const DistantRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const StyledButton = styled(Button)<ButtonProps>`
  align-self: center;
  width: 100%;
  margin-top: 12px;
`;

export type CartDetailsProps = {
  data: any[];
  quantity: number;
  subtotal: number;
  currency: string;
  shopName: string;
  count: number;
};

const CartDetails: FC<CartDetailsProps> = ({
  data,
  shopName,
  quantity,
  currency,
  subtotal,
  count,
}) => {
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    if (query === "") {
      navigate("/categories");
      return;
    }
    navigate(`/search?q=${query}`);
  };

  const onClickCheckout = () => {
    navigate("/checkout");
  };

  return (
    <Container maxW="container.xl">
      <Header onSearch={onSearch} />
      <Content>
        <HeaderContainer>
          <Title>{shopName}</Title>
        </HeaderContainer>
        <CardsContainer>
          <Sellers>
            <Seller>
              {data.map((item) => (
                <CartItem item={item} />
              ))}
            </Seller>
          </Sellers>
          <Checkout>
            <DistantRow>
              <Label>Item(s):</Label>
              <div>{count}</div>
            </DistantRow>

            <DistantRow>
              <Label>Shipping:</Label>
              <div>included</div>
            </DistantRow>
            <DistantRow>
              <Label>Tax:</Label>
              <div>included</div>
            </DistantRow>
            <DistantRow>
              <Label>Total: </Label>
              <ProductPrice>
                {formatAmount(subtotal)} {currency}
              </ProductPrice>
            </DistantRow>
            <StyledButton
              secondary={true}
              label="Checkout"
              onClick={onClickCheckout}
            />
          </Checkout>
        </CardsContainer>
      </Content>
      <Footer />
    </Container>
  );
};

export default CartDetails;
