import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader as DefaultModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button as DefaultButton,
  Flex,
  Box,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";

const ModalHeader = styled(DefaultModalHeader)`
  font-weight: 400;
`;

const CloseButton = styled(DefaultButton)`
  background-color: #fff;
  color: #4724fe;
  margin-right: 24px;
  &:hover {
    background-color: #fff;
  }
`;

const Button = styled(DefaultButton)`
  background-color: #4724fe;
  color: #fff;
`;

const ImageWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 2rem;
  width: 230px;
  min-height: 140px;
`;

const Image = styled.img`
  max-width: 154px;
`;

const ItemTitle = styled.h2`
  font-family: RockfordSansLight;
  margin-bottom: 0.5rem;
`;

const Breadcrumbs = styled.div`
  margin-top: 0.5rem;
`;

const B = styled.b`
  font-weight: 500;
`;

const Title = styled.header`
  font-size: 20px;
  margin-top: 2rem;
`;

const Description = styled.p`
  margin-top: 0.5rem;
  font-size: 14.5px;
`;

const Wrapper = styled.div`
  font-family: RockfordSansLight;
`;

const Properties = styled(Box)`
  font-family: RockfordSansLight;
  font-size: 14.5px;
`;

const Dl = styled.dl`
  display: table-row;
  font-family: RockfordSansLight;
`;

const Dt = styled.dt`
  display: table-cell;
  width: 140px;
  padding-top: 0.5rem;
`;

export type SeeDetailsDialogProps = {
  item: any;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onUseThisProduct: (item: any) => void;
  title?: string;
};

const SeeDetailsDialog: FC<SeeDetailsDialogProps> = ({
  item,
  isOpen,
  onOpen,
  onClose,
  onUseThisProduct,
}) => {
  if (!item) {
    return null;
  }
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection={"row"}>
              <ImageWrapper>
                <Image alt="" src={item.image} />
              </ImageWrapper>
              <Box>
                <ItemTitle>{item.name}</ItemTitle>
                <Breadcrumbs>
                  <B>
                    {
                      "Computers/Tablets & Networking > Laptops & Netbooks > Apple Laptops"
                    }
                  </B>
                </Breadcrumbs>
              </Box>
            </Flex>
            <Wrapper>
              <Title>Product Information</Title>
              <Description>
                The Apple MacBook Pro with Touchbar and Touch ID renders
                performance and multitasking efficiency with its integrated 8 GB
                RAM. This device is powered by a reliable 3.1 GHz Intel Core i5
                processor. Its 512 GB flash drive provides storage space for
                digital content. Also, it features a convenient 13.3-inch
                display that provides a clear view of files, apps and documents.
                In addition, this space gray Apple notebook comes pre-installed
                with the Mac OS Sierra.
              </Description>
              <Title>Product Identifiers</Title>
              <Properties>
                <Dl>
                  <Dt>Brand</Dt>
                  <dd>Apple</dd>
                </Dl>
                <Dl>
                  <Dt>MPN</Dt>
                  <dd>MPXW2LL/A</dd>
                </Dl>
                <Dl>
                  <Dt>Ean</Dt>
                  <dd>0711005516273</dd>
                </Dl>
                <Dl>
                  <Dt>GTIN</Dt>
                  <dd>0711005516273</dd>
                </Dl>
                <Dl>
                  <Dt>Model</Dt>
                  <dd>MPXW2LL/A</dd>
                </Dl>
                <Dl>
                  <Dt>Product ID</Dt>
                  <dd>238139932</dd>
                </Dl>
              </Properties>
              <Title>Product Key Features</Title>
              <Properties>
                <Dl>
                  <Dt>SDD Capacity</Dt>
                  <dd>250 GB</dd>
                </Dl>
                <Dl>
                  <Dt>Connectivity</Dt>
                  <dd>DisplayPort, USB-C, USB 3.1</dd>
                </Dl>
                <Dl>
                  <Dt>GPU</Dt>
                  <dd>Intel Iris Plus Graphics 650</dd>
                </Dl>
                <Dl>
                  <Dt>Operating System</Dt>
                  <dd>macOS 10.12, Sierra</dd>
                </Dl>
                <Dl>
                  <Dt>Color</Dt>
                  <dd>Gray</dd>
                </Dl>
                <Dl>
                  <Dt>Screen Size</Dt>
                  <dd>13.3 in</dd>
                </Dl>
                <Dl>
                  <Dt>Processor Speed</Dt>
                  <dd>3.10 GHz</dd>
                </Dl>
                <Dl>
                  <Dt>Processor</Dt>
                  <dd>Intel Core i5</dd>
                </Dl>
                <Dl>
                  <Dt>RAM Size</Dt>
                  <dd>8 GB</dd>
                </Dl>
                <Dl>
                  <Dt>Series</Dt>
                  <dd>MacBook Pro</dd>
                </Dl>
                <Dl>
                  <Dt>Maximum Resolution</Dt>
                  <dd>2560 x 1600</dd>
                </Dl>
                <Dl>
                  <Dt>Release Year</Dt>
                  <dd>2017</dd>
                </Dl>
              </Properties>
              <Title>Dimensions</Title>
              <Properties>
                <Dl>
                  <Dt>Item Length</Dt>
                  <dd>8.4"</dd>
                </Dl>
                <Dl>
                  <Dt>Item Height</Dt>
                  <dd>0.6 in</dd>
                </Dl>
                <Dl>
                  <Dt>Item Width</Dt>
                  <dd>12"</dd>
                </Dl>
                <Dl>
                  <Dt>Item Weight</Dt>
                  <dd>1.37 kg</dd>
                </Dl>
              </Properties>
            </Wrapper>
          </ModalBody>
          <ModalFooter>
            <CloseButton onClick={onClose}>Close</CloseButton>
            <Button onClick={() => onUseThisProduct(item)}>Use this product</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SeeDetailsDialog;
