import { List, ListItem } from "@chakra-ui/react";
import { FC } from "react";
import Draft from "../Draft";

export type DraftListProps = {
  drafts: any[];
  onClick: (id: string) => void;
  checkStatuses: { [key: string]: boolean };
  setChecked: (id: string, checked: boolean) => void;
};

const DraftList: FC<DraftListProps> = ({
  drafts,
  onClick,
  checkStatuses,
  setChecked,
}) => {
  return (
    <List>
      {drafts.map((draft) => (
        <ListItem key={draft.id}>
          <Draft
            item={draft}
            onClick={onClick}
            selected={checkStatuses[draft.id]}
            setChecked={setChecked}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default DraftList;
