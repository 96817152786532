import { FC } from "react";
import styled from "@emotion/styled";
import {
  Input as DefaultInput,
  Button as DefaultButton,
  Flex,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import SearchContainer from "Components/SearchContainer";
import Suggestions from "./Suggestions";
import { CloseOutline } from "@styled-icons/evaicons-outline";
import BrowseCategories from "./BrowseCategories";
import LatestDrafts from "./LatestDrafts";

const Content = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const Wrapper = styled.div`
  width: 1200px;

  @media (max-width: 1700px) {
    width: 1000px;
  }

  @media (max-width: 1500px) {
    width: 900px;
  }

  @media (max-width: 1000px) {
    width: 800px;
  }
  
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Input = styled(DefaultInput)`
  height: 50px;
`;

const Button = styled(DefaultButton)`
  height: 50px;
  width: 128px;
  margin-left: 20px;
  background-color: #4724fe;
  color: #fff;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Sell: FC = () => {
  const navigate = useNavigate();
  const results = [
    {
      id: 1,
      name: "Apple MacBook Air 13in (256GB SSD, M1, 8GB) Laptop - Space Gray - MGN63LL/A (November, 2020)",
      image: require("Assets/pictures/s-l640.jpg"),
    },
    {
      id: 2,
      name: `Apple MacBook Pro 13" (256GB SSD, Intel Core i5 8th Gen., 1.4 GHz, 8GB) Laptop - Space Gray - MUHP2LL/A (July, 2019)`,
      image: require("Assets/pictures/s-l640 (1).jpg"),
    },
    {
      id: 3,
      name: `Apple MacBook Pro 13in (256GB SSD, M1, 8GB) Laptop - Space Gray - MYD82LL/A (November, 2020)`,
      image: require("Assets/pictures/s-l640 (2).jpg"),
    },
    {
      id: 4,
      name: `Apple MacBook Pro A1278 13.3" Laptop - MC700LL/A (February, 2011)`,
      image: require("Assets/pictures/s-l640 (3).jpg"),
    },
    {
      id: 5,
      name: `Apple MacBook Pro 13.3" (128GB, Intel Core i5, 2.7Ghz, 16GB RAM) Laptop - ‎MF839LL/A`,
      image: require("Assets/pictures/s-l640 (4).jpg"),
    },
    {
      id: 6,
      name: `Apple MacBook Air 13.3" M1 8GB 256GB SSD - Mgn63ll/a 2020, Gold`,
      image: require("Assets/pictures/s-l640 (5).jpg"),
    },
  ];
  const categories = [
    {
      id: "1",
      name: "Cameras & Photo",
    },
    {
      id: "2",
      name: "Cell Phones & Accessories",
    },
    {
      id: "3",
      name: "Clothing, Shoes & Accessories",
    },
    {
      id: "4",
      name: "Computers & Tablets",
    },
    {
      id: "5",
      name: "Consumer Electronics",
    },
    {
      id: "6",
      name: "Jewelry & Watches",
    },
    {
      id: "7",
      name: "Home & Garden",
    },
    {
      id: "8",
      name: "Sporting Goods",
    },
    {
      id: "9",
      name: " Toys & Hobbies",
    },
    {
      id: "10",
      name: "Video Games & Consoles",
    },
  ];

  const subcategories: any[] = [];

  const onSelectCategory = (category: string) => {};

  return (
    <div>
      <Content>
        <Wrapper>
          <Title>Tell us what you're selling</Title>
          <Row>
            <Flex flex={1} flexDirection="column">
              <Suggestions results={[]} isOpen={false}>
                <InputGroup>
                  <Input
                    placeholder="Enter UPC, PID, part number, or product name"
                    paddingRight="35px"
                  />
                  {false && (
                    <InputRightElement
                      onClick={() => {}}
                      children={<CloseOutline size={24} color="#718096" />}
                      height="50px"
                      cursor={"pointer"}
                    />
                  )}
                </InputGroup>
              </Suggestions>
              {true && (
                <BrowseCategories
                  categories={categories}
                  onSelect={onSelectCategory}
                  subcategories={subcategories}
                />
              )}
            </Flex>
            <Button onClick={() => navigate("/create")}>Get started</Button>
          </Row>
          {false && <SearchContainer results={results} />}
          {true && <LatestDrafts />}
        </Wrapper>
      </Content>
    </div>
  );
};

export default Sell;
