import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "Components/Button";
import Header from "Components/Header";
import Item from "Containers/Item";
import { FC } from "react";

const Image = styled.img`
  max-width: 400px;
`;

const Wrapper = styled.div`
  padding-top: 50px;
  width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 18px;
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-family: RockfordSansLight;
  margin-right: 10px;
  width: 150px;
  text-align: right;
`;

const Text = styled.div<{ bold?: boolean }>`
  font-family: RockfordSansLight;
  ${({ bold }) =>
    bold &&
    css`
      font-family: RockfordSansMedium;
    `};
`;

const ItemDetails = styled.div``;

const ImageWrapper = styled.div`
  height: 500px;
  width: 500px;
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e6e6;

  border-radius: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Line = styled(Row)`
  height: 40px;
  align-items: center;
`;

const Price = styled(Text)`
  font-size: 24px;
  width: 200px;
`;

const Stat = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  height: 150px;
`;

const Numbers = styled.div`
  font-size: 1.5rem;
  text-align: center;
  color: #4724fe;
`;

const Separator = styled.div`
  border: 1px solid #e2e2e27b;
  height: 40px;
`;

const MVola = styled.img`
  width: 60px;
`;

export type PreviewListingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  item: any;
};

const PreviewListingModal: FC<PreviewListingModalProps> = ({
  isOpen,
  onClose,
  item,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent borderRadius={0}>
        <ModalHeader>Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Item />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PreviewListingModal;
