import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { WebpMachine } from "webp-hero";
import { ApolloProvider } from "@apollo/client";
import client from "./ApolloClient";
import createStore from "Redux/store";

const webpMachine = new WebpMachine();
webpMachine.polyfillDocument();

const engine = new Styletron();

// if (process.env.NODE_ENV === 'development') {
const { worker } = require("./Mocks/browser");
worker.start();
// }

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={createStore()}>
    <StyletronProvider value={engine}>
      <BaseProvider theme={LightTheme}>
        <ApolloProvider client={client}>
          <ChakraProvider>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </ChakraProvider>
        </ApolloProvider>
      </BaseProvider>
    </StyletronProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
