import { useQuery } from "@apollo/client";
import { GET_FEATURED_PRODUCTS, GET_PRODUCT_BY_ID } from "../GraphQL/Products/query";
import { GetProducts, GetProducts_products, Get_product_by_id, Get_product_variables } from "../GraphQL/Products/types";

interface IUseProductsQuery {
    getFeaturedProducts: GetProducts | undefined;
    featuredProductsError: Error | undefined;
    featuredProductsLoading: boolean;
    getProduct: Get_product_by_id | undefined;
    productError: Error | undefined;
    productLoading: boolean;
}
export const useProductsQuery = (id?:string): IUseProductsQuery => {
    const {
        data:getFeaturedProducts,
        loading:featuredProductsLoading,
        error:featuredProductsError
    } = useQuery<GetProducts, GetProducts_products>(GET_FEATURED_PRODUCTS)

    const { 
        data: getProduct,
        loading: productLoading,
        error: productError
     } = useQuery<Get_product_by_id, Get_product_variables>(GET_PRODUCT_BY_ID, {
        variables: {
            id : id || ""
        }
    })
 return {
        getFeaturedProducts,
        featuredProductsError,
        featuredProductsLoading,
        getProduct,
        productError,
        productLoading
 }
}

