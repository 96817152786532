import { Box } from "@chakra-ui/react";
import SellingActivity from "Components/SellingActivity";
import { FC } from "react";

export type OverviewProps = {
  onListAnItem: () => void;
};

const Overview: FC<OverviewProps> = ({ onListAnItem }) => {
  return (
    <div>
      <SellingActivity onListAnItem={onListAnItem} />
      <Box marginTop={"70px"} />
    </div>
  );
};

export default Overview;
