import { Box, Container, Divider } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import SellerCart from "../../Components/SellerCart";

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 1.7rem;
  font-family: RockfordSansMedium;
`;

const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  margin-left: 28px;
  margin-top: 50px;
`;

const Sellers = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 12px;
  padding: 12px;
`;

export const Cart: FC = () => {
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    if (query === "") {
      navigate("/categories");
      return;
    }
    navigate(`/search?q=${query}`);
  };
  const onClickSeller = (id: string): void => {
    navigate(`/seller/${id}`);
  };

  return (
    <Container maxW="container.xl">
      <Header onSearch={onSearch} />
      <Content>
        <HeaderContainer maxW='container.lg'>
          <Title>Carts</Title>
        </HeaderContainer>
        <Container maxW="container.xl">
          <Sellers>
            <SellerCart onClickSeller={() => onClickSeller("id")} />
            <Divider />
          </Sellers>
        </Container>
      </Content>
    </Container>
  );
};

export default Cart;
