import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { AddCircle } from "@styled-icons/fluentui-system-regular";
import HandlingTimeModal from "./HandlingTimeModal";
import PackageDetailsModal from "./PackageDetailsModal";

const Container = styled.div`
  min-height: 200px;
  padding: 25px;
  background-color: #fff;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
`;

const Row = styled(Flex)`
  flex-direction: row;
  width: 480px;
`;

const Subtitle = styled.div`
  font-family: RockfordSansMedium;
  font-size: 16.38px;
  margin-bottom: 8px;
`;

const Label = styled.div<{ color?: string; cursor?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: RockfordSansLight;
  margin-bottom: 8px;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ cursor }) =>
    cursor &&
    css`
      cursor: ${cursor};
    `}
`;

export type ShippingDetailsProps = {};

const ShippingDetails: FC<ShippingDetailsProps> = () => {
  const {
    isOpen: isHandlingTimeModalOpen,
    onOpen: onHandlingTimeModalOpen,
    onClose: onHandlingTimeModalClose,
  } = useDisclosure();
  const {
    isOpen: isPackageDetailsModalOpen,
    onOpen: onPackageDetailsModalOpen,
    onClose: onPackageDetailsModalClose,
  } = useDisclosure();
  return (
    <Container>
      <Title>DELIVERY</Title>
      <Subtitle>Shipping</Subtitle>
      <Row>
        <Flex width={"178px"}>
          <Box>
            <Label>Package details</Label>
            <Label>Handling time</Label>
          </Box>
        </Flex>
        <Flex flex={1}>
          <Box>
            <Label
              onClick={onPackageDetailsModalOpen}
              color="#4724fe"
              cursor="pointer"
            >
              <Box marginRight={"8px"}>
                <AddCircle size={20} />
              </Box>
              <Box>Add package details</Box>
            </Label>
            <Label
              onClick={onHandlingTimeModalOpen}
              color="#4724fe"
              cursor="pointer"
            >
              Same business day
            </Label>
          </Box>
        </Flex>
      </Row>
      <HandlingTimeModal
        isOpen={isHandlingTimeModalOpen}
        onClose={onHandlingTimeModalClose}
      />
      <PackageDetailsModal
        isOpen={isPackageDetailsModalOpen}
        onClose={onPackageDetailsModalClose}
      />
    </Container>
  );
};

export default ShippingDetails;
