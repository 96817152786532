import { graphql } from "msw";

const products = [
  {
    __typename: "Product",
    id: "7tTjHk99hCNNl6k4",
    name: "NodeJS Icon Zipped Hoodie",
    price: "70,000 Ar",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    image: require("Assets/pictures/nodejs.webp"),
    seller: {
      __typename: "User",
      id: "GPbYPlbcxc1E3r1b",
      name: "AlexQueen",
      stars: 3,
      positiveFeedback: 70,
    },
    category: "Clothing",
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
    numberOfWatchers: 1,

    condition: "new",
    quantityAvailable: 12,
    quantitySold: 40,
  },
  {
    __typename: "Product",
    id: "8caRLIqgqSyPf7nF",
    name: "Deno JS Developer Icon Classic",
    price: "110,000 Ar",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    image: require("Assets/pictures/deno.webp"),
    seller: {
      __typename: "User",
      id: "GPbYPlbcxc1E3r1b",
      name: "AlexQue",
      stars: 3,
      positiveFeedback: 70,
    },
    category: "Clothing",
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
    numberOfWatchers: 1,

    condition: "new",
    quantityAvailable: 12,
    quantitySold: 40,
  },
  {
    __typename: "Product",
    id: "bkMF9DTQ42jDidzW",
    name: "Kubernetes Icon - Dark",
    price: "40,000 Ar",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    image: require("Assets/pictures/k8s.webp"),
    seller: {
      __typename: "User",
      id: "GPbYPlbcxc1E3r1b",
      name: "AlexQue",
      stars: 3,
      positiveFeedback: 70,
    },
    category: "Clothing",
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
    numberOfWatchers: 1,

    condition: "new",
    quantityAvailable: 12,
    quantitySold: 40,
  },
  {
    __typename: "Product",
    id: "3",
    name: "DarkFreeBSD Operating System Icon",
    price: "74,000 Ar",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    image: require("Assets/pictures/freebsd.webp"),
    seller: {
      __typename: "User",
      id: "GPbYPlbcxc1E3r1b",
      name: "AlexQue",
      stars: 3,
      positiveFeedback: 70,
    },
    category: "Clothing",
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
    numberOfWatchers: 1,

    condition: "new",
    quantityAvailable: 12,
    quantitySold: 40,
  },
  {
    __typename: "Product",
    id: "4",
    name: "Relaxing Gopher Zipped Hoodie",
    price: "74,000 Ar",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    image: require("Assets/pictures/golang-hoodie.webp"),
    seller: {
      __typename: "User",
      id: "GPbYPlbcxc1E3r1b",
      name: "AlexQue",
      stars: 3,
      positiveFeedback: 70,
    },
    category: "Clothing",
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
    numberOfWatchers: 1,

    condition: "new",
    quantityAvailable: 12,
    quantitySold: 40,
  },
];

const productHandler = [
  graphql.query("GetFeaturedProducts", (req, res, ctx) => {
    return res(ctx.data({ products }));
  }),
  graphql.query("GetProductById", (req, res, ctx) => {
    const { id } = req.variables;
    return res(
      ctx.data({
        product: products.find((item) => item.id === id),
      })
    );
  }),
];

export default productHandler;
