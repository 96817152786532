import styled from "@emotion/styled";
import { Table, TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { FC } from "react";
import { CloseOutline } from "@styled-icons/evaicons-outline";
import { Box } from "@chakra-ui/react";
import Filter from "Components/Filter";

const Container = styled.div`
  margin-top: 20px;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Filters = styled(Row)`
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Clear = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(106, 115, 131);
  &:hover {
    color: #000;
  }
`;

const COLUMNS = ["Amount", "Item", "Date", "Status"];

const DATA = [
  {
    amount: "6,000,000 Ar",
    item: "Apple MacBook Air 13in (256GB SSD, M1, 8GB) ",
    date: "lrb 06 Jol 2022 00:23:41 EAT",
    status: "Payment Received",
  },
  {
    amount: "1,000,000 Ar",
    item: "Apple iPhone 8 Plus 64gb Black Verizon Factory",
    date: "lrb 06 Jol 2022 00:23:41 EAT",
    status:"Payment Received",
  },
  {
    amount: "4,000,000 Ar",
    item: 'Apple MacBook Pro 13.3" (1TB SSD, Intel Core i5 10th',
    date: "lrb 06 Jol 2022 00:23:41 EAT",
    status: "Payment Received",
  },
];

export type RecentTransactionsProps = {};

const RecentTransactions: FC<RecentTransactionsProps> = () => {
  return (
    <Container>
      <Filters>
        <Row>
          <Filter
            date
            label="Date"
            onApply={() => {}}
            options={[
              "is in the last",
              "is equal to",
              "is between",
              "is after",
              "is before",
            ]}
          />
          <Filter
            label="Amount"
            onApply={() => {}}
            options={[
              "is equal to",
              "is between",
              "is greater than",
              "is less than",
            ]}
          />
          <Filter
            label="Status"
            onApply={() => {}}
            options={["Payment Received"]}
            multi
          />
        </Row>
        <Clear>
          <IconWrapper>
            <CloseOutline size={20} />
          </IconWrapper>
          <Box fontSize={14} marginTop={"-2px"}>
            Clear Filters
          </Box>
        </Clear>
      </Filters>
      <TableBuilder data={DATA}>
        <TableBuilderColumn header="Amount">
          {(row) => <div>{row.amount}</div>}
        </TableBuilderColumn>
        <TableBuilderColumn header="Item">
          {(row) => <div>{row.item}</div>}
        </TableBuilderColumn>
        <TableBuilderColumn header="Date">
          {(row) => <div>{row.date}</div>}
        </TableBuilderColumn>
        <TableBuilderColumn header="Status">
          {(row) => <div>{row.status}</div>}
        </TableBuilderColumn>
      </TableBuilder>
    </Container>
  );
};

export default RecentTransactions;
