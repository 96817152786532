
import { FC } from "react";
import PreviewListingModal from "./PreviewListingModal";

export type PreviewListingModalWithDataProps = {
  isOpen: boolean;
  onClose: () => void;
};

const PreviewListingModalWithData: FC<PreviewListingModalWithDataProps> = (props) => {
  const item = {};
  return (
    <PreviewListingModal {...props} item={item} />
  );
};

export default PreviewListingModalWithData;
