import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';
import _ from 'lodash';

const LIST_DATA = [
  {
    id: 7,
    name: 'NodeJS Icon Zipped Hoodie',
    description: '',
    price: 110000,
    currency: 'Ar',
    image: require('Images/nodejs.webp'),
    shop: {
      id: '1',
      name: 'Mitia Mode',
      cover: require('Images/242231287_1541365739543227_5880412979109259294_n.jpg'),
    },
  },
  {
    id: 6,
    name: 'Kubernetes Icon - Dark',
    description: '',
    price: 72000,
    currency: 'Ar',
    image: require('Images/k8s.webp'),
    shop: {
      id: '1',
      name: 'Mitia Mode',
      cover: require('Images/242231287_1541365739543227_5880412979109259294_n.jpg'),
    },
  },
  {
    id: 5,
    name: 'Relaxing Gopher Zipped Hoodie',
    description: '',
    price: 80000,
    currency: 'Ar',
    image: require('Images/golang-hoodie.webp'),
    shop: {
      id: '1',
      name: 'Mitia Mode',
      cover: require('Images/242231287_1541365739543227_5880412979109259294_n.jpg'),
    },
  },
  {
    id: 1,
    name: 'Manteau doudoune à capuche et fourrure',
    description: '',
    price: 50000,
    currency: 'Ar',
    image: require('Images/281575289_1706864536326679_1190362537294478772_n.jpg'),
    shop: {
      id: '1',
      name: 'Mitia Mode',
      cover: require('Images/242231287_1541365739543227_5880412979109259294_n.jpg'),
    },
  },
  {
    id: 2,
    name: 'Bottines de marque en provenance de paris',
    description: '',
    price: 50000,
    currency: 'Ar',
    image: require('Images/281745527_1706120626401070_1918476991851392515_n.jpg'),
    shop: {
      id: '1',
      name: 'Mitia Mode',
      cover: require('Images/242231287_1541365739543227_5880412979109259294_n.jpg'),
    },
  },
  {
    id: 3,
    name: 'Pull à capuche careau homme',
    description: '',
    price: 50000,
    currency: 'Ar',
    image: require('Images/281709738_1706863956326737_2310605836636155947_n.jpg'),
    shop: {
      id: '2',
      name: 'Mitia Marque',
      cover: require('Images/81542968_2616212998616342_1631923358700404736_n.jpg'),
    },
  },
  {
    id: 4,
    name: 'Manteau doudoune à capuche et fourrure',
    description: '',
    price: 50000,
    currency: 'Ar',
    image: require('Images/281723276_1706864496326683_478838822581273716_n.jpg'),
    shop: {
      id: '2',
      name: 'Mitia Marque',
      cover: require('Images/81542968_2616212998616342_1631923358700404736_n.jpg'),
    },
  },
];

const QUANTITY = [{}];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getProduct: ['id'],
  getAllProducts: null,
  productReceived: ['product'],
  productsReceived: ['products'],
  incrementQuantity: ['item'],
  decrementQuantity: ['item'],
});

export const ProductTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  recentListings: LIST_DATA,
  quantity: QUANTITY,
});

/* ------------- Reducers ------------- */

export const productReceived = () => INITIAL_STATE;

export const productsReceived = () => INITIAL_STATE;

export const incrementQuantity = (state, {item}) => {
  return state.merge({
    quantity: {
      [item.id]: _.get(state.quantity, item.id, 1) + 1,
    },
  });
};

export const decrementQuantity = (state, {item}) => {
  if (_.get(state.quantity, item.id, 0) > 1) {
    return state.merge({
      quantity: {
        [item.id]: _.get(state.quantity, item.id, 0) - 1,
      },
    });
  }
  return state;
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PRODUCT_RECEIVED]: productReceived,
  [Types.PRODUCTS_RECEIVED]: productsReceived,
  [Types.INCREMENT_QUANTITY]: incrementQuantity,
  [Types.DECREMENT_QUANTITY]: decrementQuantity,
});
