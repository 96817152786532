import {
  Box,
  Flex,
  Modal,
  ModalBody as DefaultModalBody,
  ModalContent as DefaultModalContent,
  ModalHeader as DefaultModalHeader,
  ModalOverlay,
  Input as DefaultInput,
  InputGroup as DefaultInputGroup,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC, useState } from "react";
import { css } from "@emotion/react";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";

const ModalHeader = styled(DefaultModalHeader)`
  font-family: RockfordSansMedium;
  font-weight: normal;
  text-align: center;
  margin-right: 40px;
`;

const ModalContent = styled(DefaultModalContent)`
  height: 87%;
  overflow-y: auto;
  padding: 16px 32px 0;
  padding-top: 0;
`;

const ModalBody = styled(DefaultModalBody)`
  padding: 0;
`;

const Text = styled.div<{ color?: string }>`
  font-family: RockfordSansLight;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

const RadioText = styled(Text)``;

const Row = styled(Flex)`
  flex-direction: row;
`;

const Input = styled(DefaultInput)`
  background-color: #f9f9f9;
  &:focus {
    background-color: #fff;
  }
`;

const InputGroup = styled(DefaultInputGroup)`
  flex: 1;
  margin: 8px 0 16px;
  margin-right: 8px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 18px;
  color: #4724fe;
  font-weight: 400;
`;

export type PackageDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const PackageDetailsModal: FC<PackageDetailsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [value, setValue] = useState("1");
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Package details</ModalHeader>
        <CloseButton variant={"unstyled"} onClick={onClose}>
          Done
        </CloseButton>

        <ModalBody>
          <RadioGroup
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            name="number"
            align={ALIGN.vertical}
          >
            <Radio value="1" overrides={styles.radio}>
              <RadioText>I don't know package details</RadioText>
            </Radio>
            <Radio value="2" overrides={styles.radio}>
              <RadioText>Enter package details</RadioText>
            </Radio>
          </RadioGroup>
          <Box marginLeft={"24px"}>
            <Text>Packet weight</Text>
            <InputGroup>
              <Input />
              <InputRightElement children={<Text color="#56657a">kg</Text>} />
            </InputGroup>
            <Text>Dimensions</Text>
            <Row>
              <InputGroup>
                <Input placeholder="width" />
                <InputRightElement children={<Text color="#56657a">cm</Text>} />
              </InputGroup>
              <InputGroup>
                <Input placeholder="height" />
                <InputRightElement children={<Text color="#56657a">cm</Text>} />
              </InputGroup>
            </Row>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const styles = {
  radio: {
    Root: {
      style: () => ({
        marginBottom: "20px",
      }),
    },
  },
};

export default PackageDetailsModal;
