import styled from "@emotion/styled";
import {
  Box,
  Flex,
  Input as DefaultInput,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { css } from "@emotion/react";
import OffersModal from "./OffersModal";
import ScheduleModal from "./ScheduleModal";

const Container = styled.div`
  min-height: 200px;
  padding: 25px;
  background-color: #fff;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
`;

const Input = styled(DefaultInput)`
  background-color: #f9f9f9;
  &:focus {
    background-color: #fff;
  }
`;

const Row = styled(Flex)`
  flex-direction: row;
  width: 480px;
`;

const Separator = styled.div`
  width: 24px;
`;

const Subtitle = styled.div`
  font-family: RockfordSansMedium;
  font-size: 16.38px;
  margin-bottom: 8px;
`;

const Label = styled.div<{ color?: string; cursor?: string }>`
  font-family: RockfordSansLight;
  margin-bottom: 8px;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  ${({ cursor }) =>
    cursor &&
    css`
      cursor: ${cursor};
    `}
`;

export type SellingDetailsProps = {};

const SellingDetails: FC<SellingDetailsProps> = () => {
  const {
    isOpen: isOffersModalOpen,
    onOpen: onOffersModalOpen,
    onClose: onOffersModalClose,
  } = useDisclosure();
  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
  } = useDisclosure();
  return (
    <Container>
      <Title>PRICING</Title>
      <Subtitle>Buy It Now</Subtitle>
      <Row marginBottom={"32px"}>
        <Flex flex={1}>
          <Box>
            <Label>Price</Label>
            <InputGroup>
              <InputLeftElement color="gray.400" children="Ar" />
              <Input paddingLeft="40px" />
            </InputGroup>
          </Box>
        </Flex>
        <Separator />
        <Flex flex={1}>
          <Box>
            <Label>Quantity</Label>
            <Input />
          </Box>
        </Flex>
      </Row>
      <Row>
        <Flex width={"178px"}>
          <Box>
            <Label>Offers</Label>
            <Label>Scheduled start time</Label>
          </Box>
        </Flex>
        <Flex flex={1}>
          <Box>
            <Label onClick={onOffersModalOpen} color="#4724fe" cursor="pointer">
              Allow offers
            </Label>
            <Label
              onClick={onScheduleModalOpen}
              color="#4724fe"
              cursor="pointer"
            >
              Start immediately
            </Label>
          </Box>
        </Flex>
      </Row>
      <OffersModal isOpen={isOffersModalOpen} onClose={onOffersModalClose} />
      <ScheduleModal
        isOpen={isScheduleModalOpen}
        onClose={onScheduleModalClose}
      />
    </Container>
  );
};

export default SellingDetails;
