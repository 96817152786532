import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const LIST_DATA = ['sausage'];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getUser: ['id'],
  signIn: ['user'],
  signOut: null,
  updateUserProfile: ['user'],
  userAuthenticated: ['user'],
  userProfileUpdated: ['user'],
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  searchTerm: '',
  searching: false,
  results: LIST_DATA,
});

/* ------------- Reducers ------------- */

export const userAuthenticated = () => INITIAL_STATE;

export const userProfileUpdated = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_AUTHENTICATED]: userAuthenticated,
  [Types.USER_PROFILE_UPDATED]: userProfileUpdated,
});
