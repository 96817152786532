import { Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC, useRef } from "react";
import {
  AddPhotoAlternate,
  VideoLibrary,
} from "@styled-icons/material-outlined";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 1024px;
`;

const AddPhotos = styled(Button)`
  height: 240px;
  flex: 1;
  background-color: #f7f7f7;
  margin-right: 22px;
  font-family: RockfordSansMedium;
  font-weight: normal;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #f7f7f7;
    border: 1px solid #4724fe;
  }
`;

const Icon = styled.div`
  background-color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PhotoInput = styled.input`
  display: none;
`;

const VideoInput = styled.input`
  display: none;
`;

const ButtonLabel = styled.span`
  margin-top: 10px;
`;

const AddVideos = styled(Button)`
  height: 240px;
  width: 240px;
  background-color: #f7f7f7;
  font-family: RockfordSansMedium;
  font-weight: normal;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #f7f7f7;
    border: 1px solid #4724fe;
  }
`;

export type PlaceholderProps = {
  onSelectPhoto: () => void;
  onSelectVideo: () => void;
};

const Placeholder: FC<PlaceholderProps> = ({
  onSelectPhoto,
  onSelectVideo,
}) => {
  const photoInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  return (
    <Container>
      <AddPhotos onClick={onSelectPhoto}>
        <Icon>
          <AddPhotoAlternate size={24} />
        </Icon>
        <ButtonLabel>Add photos</ButtonLabel>
      </AddPhotos>
      <AddVideos onClick={onSelectVideo}>
        <Icon>
          <VideoLibrary size={24} />
        </Icon>
        <ButtonLabel>Add video</ButtonLabel>
      </AddVideos>
      <PhotoInput type="file" ref={photoInputRef} accept="image/*" />
      <VideoInput type="file" ref={videoInputRef} accept="video/*" />
    </Container>
  );
};

export default Placeholder;
