import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import ActionBar from "./ActionBar";
import ListingDetails from "./ListingDetails";
import ProductInfo from "./ProductInfo";
import SellingDetails from "./SellingDetails";
import ShippingDetails from "./ShippingDetails";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #fff;
`;

const Title = styled.h1`
  font-size: 24px;
  padding: 25px;
  margin-top: 50px;
`;

const Wrapper = styled.div`
  width: 1024px;
`;

const CreateListing = () => {
  const navigate = useNavigate();
  const onListItem = () => {
    navigate(-1);
  };
  const onPreview = () => {};
  const onSaveForLater = () => {
    navigate("/drafts");
  };
  const onCancel = () => {
    navigate(-1);
  };
  const itemSpecifics = {
    required: [
      {
        name: "Brand",
        value: "Apple",
        results: ["Apple"],
      },
      {
        name: "Screen Size",
        value: "13.3 in",
        results: ["13.3 in"],
      },
      {
        name: "Processor",
        value: "Intel Core i5 10th Gen.",
        results: ["Intel Core i5 10th Gen.", "Intel Core i7 10th Gen."],
      },
    ],
    additional: [
      {
        name: "Model",
        value: "MacBook Pro",
        results: ["MacBook Pro"],
      },
      {
        name: "Color",
        value: "Silver",
        results: ["Silver", "Space Gray"],
      },
      {
        name: "Release Year",
        value: "2020",
        results: [
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
        ],
      },
      {
        name: "SSD Capacity",
        value: "512GB",
        results: ["512GB", "1TB"],
      },
      {
        name: "RAM Size",
        value: "16GB",
        results: ["8GB", "16GB", "32GB"],
      },
      {
        name: "Storage Type",
        value: "SSD (Solid State Drive)",
        results: ["SSD (Solid State Drive)", "HDD (Hard Drive Disk)"],
      },
      {
        name: "Operating System",
        value: "Mac OS X 10.15, Catalina",
        results: [
          "Mac OS X 12.0, Monterey",
          "Mac OS X 11.0, Big Sur",
          "Mac OS X 10.15, Catalina",
        ],
      },
    ],
  };
  return (
    <Container>
      <Wrapper>
        <Title>Complete your listing</Title>
        <ProductInfo />
        <ListingDetails itemSpecifics={itemSpecifics} />
        <SellingDetails />
        <ShippingDetails />
        <ActionBar
          onListItem={onListItem}
          onPreview={onPreview}
          onSaveForLater={onSaveForLater}
          onCancel={onCancel}
        />
      </Wrapper>
    </Container>
  );
};

export default CreateListing;
