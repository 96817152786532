import { FC, useState } from "react";
import Header from "Components/Header";
import LeftNavigation from "Components/LeftNavigation";
import styled from "@emotion/styled";
import {  useDisclosure } from "@chakra-ui/react";
import SummaryTitle from "Components/SummaryTitle";
import Placehorder from "Components/Placeholder";
import { useNavigate } from "react-router-dom";
import DraftList from "./DraftList";
import Action from "./Action";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const DraftsContainer = styled.div`
  @media (min-width: 1560px) {
    width: 900px;
  }
  @media (max-width: 1560px) {
    width: 800px;
  }
  @media (max-width: 1200px) {
    width: 700px;
  }
`;

const RightPane = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Link = styled.a`
  color: #4724fe;
  font-family: RockfordSansRegular;
  font-weight: normal;
  font-size: 18px;
  margin-top: 10px;
`;

const Drafts: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectAll, setSelectAll] = useState(false);
  const [checkStatuses, setCheckStatuses] = useState(
    {} as { [key: string]: boolean }
  );
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    navigate(`/search?q=${query}`);
  };

  const drafts = [
    {
      id: "1",
      title:
        "Apple MacBook Pro 13in (256GB SSD, M1, 8GB) Laptop - Space Gray - MGN63LL/A (November, 2020)",
      image: require("Assets/pictures/s-l640.jpg"),
    },
    {
      id: "2",
      title:
        'Apple MacBook Pro 13.3" (1TB SSD, Intel Core i5 10th Gen., 3.80 GHz, 16GB) Laptop',
    },
    {
      id: "3",
      title: "Apple iPhone 8 Plus 64gb Black Verizon Factory Unlocked",
      image: require("Assets/pictures/iphone.jpg"),
    },
  ];

  const onClick = (id: string) => {
    navigate(`/draft/${id}`);
  };

  const onDeleteSelected = () => {
    onClose();
  };

  const onSelectOrDeselectAll = (checked: boolean) => {
    setCheckStatuses(
      drafts.reduce((acc, draft) => {
        acc[draft.id] = checked;
        return acc;
      }, {} as { [key: string]: boolean })
    );
    setSelectAll(checked);
  };

  const setChecked = (id: string, checked: boolean) => {
    const selected = Object.values({ ...checkStatuses, [id]: checked }).filter(
      (v) => v
    );
    if (selected.length === 0 && selectAll) {
      setSelectAll(false);
    }
    setCheckStatuses({ ...checkStatuses, [id]: checked });
  };

  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <LeftNavigation active="drafts" />
        <DraftsContainer>
          <SummaryTitle text="Drafts" />
          {drafts.length === 0 && (
            <Placehorder text="You don't have any drafts.">
              <Link href="/sell">Start a listing</Link>
            </Placehorder>
          )}
          <Action
            selectAll={selectAll}
            checkStatuses={checkStatuses}
            onSelectOrDeselectAll={onSelectOrDeselectAll}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            onDeleteSelected={onDeleteSelected}
          />
          <DraftList
            drafts={drafts}
            onClick={onClick}
            checkStatuses={checkStatuses}
            setChecked={setChecked}
          />
        </DraftsContainer>
        <RightPane />
      </Content>
    </div>
  );
};

export default Drafts;
