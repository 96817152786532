import { useQuery } from "@apollo/client";
import { GET_FEATURED_CATEGORIES } from "../GraphQL/Category/query";
import { GetCategories, GetCategories_categories } from "../GraphQL/Category/types";

interface IUseCategoriesQuery {
    getFeaturedCategories: GetCategories | undefined;
    featuredCategoriesError: Error | undefined;
    featuredCategoriesLoading: boolean;
}
export const useCategoriesQuery = ():IUseCategoriesQuery => {
    const { 
        data: getFeaturedCategories,
        loading: featuredCategoriesLoading,
        error: featuredCategoriesError
    } = useQuery<GetCategories, GetCategories_categories>(GET_FEATURED_CATEGORIES);

    return {
        getFeaturedCategories,
        featuredCategoriesError,
        featuredCategoriesLoading
    }
}