import styled from "@emotion/styled";
import { FC, useState } from "react";
import { PlusCircle } from "@styled-icons/evaicons-solid";
import {
  Box,
  ButtonProps,
  Flex,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent as DefaultPopoverContent,
  PopoverTrigger,
  Select,
} from "@chakra-ui/react";
import DefaultButton from "Components/Button";
import { DatePicker } from "baseui/datepicker";

const Container = styled.button`
  border: 1px dashed rgb(192, 200, 210);
  height: 24px;
  border-radius: 12px;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
  color: rgb(106, 115, 131);
  font-family: RockfordSansMedium;
  &:hover {
    background-color: rgba(192, 200, 210, 0.171);
  }
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopoverContent = styled(DefaultPopoverContent)`
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(156, 163, 175, 0.2) 0px 20px 25px -5px,
    rgba(156, 163, 175, 0.2) 0px 8px 10px -6px !important;
`;

const Title = styled.div`
  font-family: RockfordSansMedium;
  margin-bottom: 10px;
`;

const Button = styled(DefaultButton)<ButtonProps>`
  width: 100%;
  height: 30px;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 32px;
`;

export type FilterProps = {
  label: string;
  date?: boolean;
  multi?: boolean;
  options: string[];
  onApply: () => void;
};

const Filter: FC<FilterProps> = ({ label, onApply, options, multi, date }) => {
  const [value, setValue] = useState(options[0]);
  return (
    <>
      <Popover
        placement="bottom-start"
        closeOnBlur={!date || value === "is in the last"}
      >
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Container>
                <IconWrapper>
                  <PlusCircle size={15} />
                </IconWrapper>
                <Box>{label}</Box>
              </Container>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Title>Filter by {label}</Title>
                {!multi && (
                  <>
                    <Select
                      size="sm"
                      value={value}
                      onChange={(ev) => setValue(ev.target.value)}
                    >
                      {options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                    {date &&
                      value !== "is in the last" &&
                      value !== "is between" && (
                        <DatePicker
                          overrides={{
                            Input: {
                              props: {
                                overrides: {
                                  Input: {
                                    style: () => ({
                                      outline: "none",
                                      backgroundColor: "#fff",
                                      height: "32px",
                                      padding: 0,
                                      paddingLeft: "12px",
                                      paddingRight: "12px",
                                    }),
                                  },
                                },
                              },
                            },
                            InputWrapper: {
                              style: () => ({
                                marginTop: "15px",
                              }),
                            },
                          }}
                        />
                      )}
                    {date && value === "is between" && (
                      <Row>
                        <Flex flex={1}>
                          <DatePicker
                            overrides={{
                              Input: {
                                props: {
                                  overrides: {
                                    Input: {
                                      style: () => ({
                                        outline: "none",
                                        backgroundColor: "#fff",
                                        height: "32px",
                                        padding: 0,
                                        textAlign: "center",
                                      }),
                                    },
                                  },
                                },
                              },
                              InputWrapper: {
                                style: () => ({
                                  padding: 0,
                                }),
                              }
                            }}
                          />
                        </Flex>
                        <Separator>and</Separator>
                        <Flex flex={1}>
                          <DatePicker
                            overrides={{
                              Input: {
                                props: {
                                  overrides: {
                                    Input: {
                                      style: () => ({
                                        outline: "none",
                                        backgroundColor: "#fff",
                                        height: "32px",
                                        padding: 0,
                                        textAlign: "center",
                                      }),
                                    },
                                  },
                                },
                              },
                              InputWrapper: {
                                style: () => ({
                                  padding: 0,
                                }),
                              }
                            }}
                          />
                        </Flex>
                      </Row>
                    )}
                    {date && value === "is in the last" && (
                      <Input placeholder="0" size={"sm"} marginTop="10px" />
                    )}
                    {!date && (
                      <Input placeholder="0" size={"sm"} marginTop="10px" />
                    )}
                  </>
                )}
                <Box marginTop={"15px"}>
                  <Button
                    primary
                    label="Apply"
                    onClick={() => {
                      onClose();
                      onApply();
                    }}
                  />
                </Box>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </>
  );
};

export default Filter;
