import styled from "@emotion/styled";
import { Input as DefaultInput } from "@chakra-ui/react";
import { FC } from "react";

const Container = styled.div`
  min-height: 201px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
`;

const Input = styled(DefaultInput)`
  background-color: #f9f9f9;
  &:focus {
    background-color: #fff;
  }
`;

const Text = styled.div`
  font-family: RockfordSansLight;
  margin-bottom: 8px;
`;

export type ItemTitleProps = {};

const ItemTitle: FC<ItemTitleProps> = () => {
  return (
    <Container>
      <Title>TITLE</Title>
      <Text>Item title</Text>
      <Input />
    </Container>
  );
};

export default ItemTitle;
