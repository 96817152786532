import { FC, useState } from "react";
import styled from "@emotion/styled";
import { ChevronUpOutline } from "@styled-icons/evaicons-outline";

const Container = styled.div`
  width: 880px;
`;

const Wrapper = styled.div`
  height: 1500px;
`;

const Title = styled.h1`
  line-height: 25px;
  font-size: 25px;
  margin-bottom: 23px;
  font-family: RockfordSansMedium;
`;

const ScrolToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #e9e9e986;
  height: 40px;
  width: 40px;
`;

export type CategoryContainerProps = {};

const CategoryContainer: FC = () => {
  return (
    <Container>
      <Wrapper>
        <Title id="electronics">Electronics</Title>
      </Wrapper>
      <Wrapper>
        <Title id="computers">Computers / Tablets</Title>
      </Wrapper>
      <ScrolToTopButton onClick={() => window.scrollTo(0, 0)}>
        <ChevronUpOutline size={32} />
      </ScrolToTopButton>
    </Container>
  );
};

export default CategoryContainer;
