import styled from "@emotion/styled";
import { FC } from "react";

const Container = styled.div`
  width: 880px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
`;

const Title = styled.h2`
  font-size: 1.875rem;
  font-family: RockfordSansMedium;
`;

export type SummaryTitleProps = {
  text: string;
};

const SummaryTitle: FC<SummaryTitleProps> = (props) => {
  return (
    <Container {...props}>
      <Title>{props.text}</Title>
    </Container>
  );
};

export default SummaryTitle;
