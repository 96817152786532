import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem as DefaultAccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";

const Container = styled.div`
  width: 200px;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const Aspects = styled.div`
  font-family: RockfordSansLight;
  font-size: 14px;
  margin-right: 5px;
`;

const AccordionItem = styled(DefaultAccordionItem)`
  margin-bottom: 1rem;
`;

export type SearchRefinementsProps = {};

const SearchRefinements: FC<SearchRefinementsProps> = () => {
  return (
    <Container>
      <Accordion allowMultiple>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Brand</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Series</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Model</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Color</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Screen Size</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Processor</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Processor Speed</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>RAM Size</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Storage Type</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Hard Drive Capacity</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Operating System</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Release Year</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Maximum Resolution</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>Connectivity</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
        <AccordionItem border={"none"}>
          <AccordionButton>
            <Aspects>SSD Capacity</Aspects>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel></AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );
};

export default SearchRefinements;
