import {
  Box,
  Grid,
  GridItem as DefaultGridItem,
  Button as DefaultButton,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import SeeDetailsDialog from "Components/SeeDetailsDialog";
import { FC, useState } from "react";

const Title = styled.h2`
  font-size: 1.375rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
`;

const GridItem = styled(DefaultGridItem)`
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  min-height: 278px;
`;

const ItemTitle = styled.h2`
  margin-bottom: 0.5rem;
`;

const ImageWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Image = styled.img`
  max-width: 154px;
`;

const Dl = styled.dl`
  display: table-row;
  font-family: RockfordSansLight;
`;

const Dt = styled.dt`
  display: table-cell;
  width: 140px;
`;

const Button = styled(DefaultButton)`
  background-color: #23fdd8;
  color: #2c398f;
  width: 128px;
  border-radius: 20px;
  margin-top: 1rem;
`;

export type SearchResultsProps = {
  results: any[];
  onUseThisProduct: (item: any) => void;
};

const SearchResults: FC<SearchResultsProps> = ({ results, onUseThisProduct }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState<any>();
  const onSelectItem = (item: any) => {
    setSelectedItem(item);
    onOpen();
  };
  const _onUseThisProduct = (item: any) => {
    onUseThisProduct(item);
    onClose();
  }
  return (
    <Container>
      <Title>Similar products in our catalog</Title>
      <Grid templateColumns={"repeat(2, 1fr)"} marginTop="2rem">
        {results.map((item) => (
          <GridItem key={item.id} w="100%">
            <ImageWrapper>
              <Image alt="" src={item.image} />
            </ImageWrapper>
            <Box>
              <ItemTitle>{item.name}</ItemTitle>
              <Dl>
                <Dt>SDD Capacity</Dt>
                <dd>250 GB</dd>
              </Dl>
              <Dl>
                <Dt>Connectivity</Dt>
                <dd>DisplayPort, USB-C, USB 3.1</dd>
              </Dl>
              <Dl>
                <Dt>Brand</Dt>
                <dd>Apple</dd>
              </Dl>
              <Button onClick={() => onSelectItem(item)}>Select</Button>
            </Box>
          </GridItem>
        ))}
      </Grid>
      <SeeDetailsDialog
        item={selectedItem}
        onOpen={onOpen}
        onClose={onClose}
        onUseThisProduct={_onUseThisProduct}
        isOpen={isOpen}
      />
    </Container>
  );
};

export default SearchResults;
