import { FC, useState } from "react";
import Header from "Components/Header";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import CategoryNavigation from "Components/CategoryNavigation";
import { Flex } from "@chakra-ui/react";
import CategoryContainer from "./CategoryContainer";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const Browse: FC = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("electronics");
  const onSelect = (category: string) => {
    setActive(category);
  };
  const onSearch = (query: string) => {
    if (query.length > 0) {
      navigate(`/search?q=${query}`);
    }
  };
  const categories = [
    { id: 1, slug: "electronics", name: "Electronics" },
    { id: 2, slug: "computers", name: "Computers / Tablets" },
    { id: 3, slug: "fashion", name: "Fashion" },
    { id: 4, slug: "beauty", name: "Beauty" },
    { id: 5, slug: "home-garden", name: "Home & Garden" },
    { id: 6, slug: "sports", name: "Sports" },
    { id: 7, slug: "motors", name: "Motors" },
    { id: 8, slug: "ohters", name: "Others" },
  ];

  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <CategoryNavigation
          active={active}
          onSelect={onSelect}
          categories={categories}
        />
        <CategoryContainer />
        <Flex flex={1} />
      </Content>
    </div>
  );
};

export default Browse;
