import _, { sum } from "lodash";
import { FC } from "react";
import { useSelector } from "react-redux";
import Header, { HeaderProps } from "./Header";

const HeaderWithData: FC<HeaderProps> = (props) => {
  const { quantityTotal } = useSelector((state: any) => state.cart) as any;
  const count = _.defaultTo(sum(Object.values(quantityTotal)), 0);

  return <Header {...props} counter={count} />;
};

export default HeaderWithData;
