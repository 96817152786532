import { gql } from "@apollo/client";
import { PRODUCT_BASIC_INFO_FRAGMENT } from "../fagments";

export const GET_FEATURED_PRODUCTS = gql`
${PRODUCT_BASIC_INFO_FRAGMENT}
  query GetFeaturedProducts {
    products {
      ...ProductBasicInfo
    }
  }
 `;

export const  GET_PRODUCT_BY_ID = gql`
${PRODUCT_BASIC_INFO_FRAGMENT}
  query GetProductById($id: ID!) {
    product(id: $id) {
      ...ProductBasicInfo
    }
  }`
