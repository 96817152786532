import { graphql } from "msw";

const categories = [
  {
    id: "cl63tuxcg000001oh7nevgw34",
    name: "Smartphones",
    image: require("Assets/pictures/smartphones.webp"),
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "cl63tv61c000001oj3l5l5zi1",
    name: "Gaming",
    image: require("Assets/pictures/gaming.webp"),
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    id: "cl63tvdd6000001nofex2b32c",
    name: "Clothing",
    image: require("Assets/pictures/clothing.webp"),
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
];

const categoryHandler = [
  graphql.query("GetFeaturedCategories", (req, res, ctx) => {
    return res(ctx.data({ categories }));
  }),
];

export default categoryHandler;
