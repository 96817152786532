import { gql } from "@apollo/client";

export const USER_INFO_FRAGMENT = gql`
    fragment UserInformation on User {
        id
        name
        stars
        positiveFeedback
        createdAt
        updatedAt
    }`;