import BigNumber from 'bignumber.js';
import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';
import {append, filter, sum} from 'ramda';
import _ from 'lodash';

const LIST_DATA = [
  {
    id: 1,
    title: 'Mitia Mode',
    items: [{id: 1}],
    price: new BigNumber(50000).toFormat(2, {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
    }),
    currency: 'Ar',
    cover: require('Images/242231287_1541365739543227_5880412979109259294_n.jpg'),
  },
  {
    id: 2,
    title: 'Mitia Marque',
    items: [{id: 1}, {id: 2}, {id: 3}],
    price: new BigNumber(126000).toFormat(2, {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
    }),
    currency: 'Ar',
    cover: require('Images/81542968_2616212998616342_1631923358700404736_n.jpg'),
  },
];

const SHOPS = {};

const CURRENT_CART = {};

const QUANTITY = {};

const QUANTITY_TOTAL = {};

const SUBTOTAL = {};

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  addItemToCart: ['item', 'quantity'],
  updateCart: ['item', 'quantity'],
  removeItemFromCart: ['item'],
  getCart: ['id'],
  getAllCarts: null,
  cartReceived: ['cart'],
  cartsReceived: ['carts'],
  resetCart: null,
});

export const CartTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: LIST_DATA,
  currentCart: CURRENT_CART,
  quantity: QUANTITY,
  shops: SHOPS,
  quantityTotal: QUANTITY_TOTAL,
  subtotal: SUBTOTAL,
});

/* ------------- Reducers ------------- */

export const cartReceived = () => INITIAL_STATE;

export const cartsReceived = () => INITIAL_STATE;

export const addItemToCart = (
  state,
  {item, quantity},
) => {
  const cart = append(
    item,
    filter(x => x.id !== item.id, _.get(state.currentCart, item.shop.id, [])),
  );
  const quantityTotal =
    sum(cart.map(x => _.get(state.quantity, x.id, 0))) + quantity;
  const newState = state.merge({
    currentCart: state.currentCart.merge({[item.shop.id]: cart}),
    shops: state.shops.merge({[item.shop.id]: item.shop}),
    quantity: state.quantity.merge({
      [item.id]: _.get(state.quantity, item.id, 0) + quantity,
    }),
    quantityTotal: state.quantityTotal.merge({
      [item.shop.id]: quantityTotal,
    }),
  });
  return newState.merge({
    subtotal: newState.subtotal.merge({
      [item.shop.id]: sum(
        cart.map((x) => x.price * _.get(newState.quantity, x.id, 0)),
      ),
    }),
  });
};

export const updateCart = (
  state,
  {item, quantity},
) => {
  return addItemToCart(removeItemFromCart(state, {item}), {item, quantity});
};

export const removeItemFromCart = (state, {item}) => {
  const cart = filter(
    x => x.id !== item.id,
    _.get(state.currentCart, item.shop.id, []),
  );
  return state.merge({
    currentCart:
      cart.length > 0
        ? state.currentCart.merge({
            [item.shop.id]: cart,
          })
        : state.currentCart.without(item.shop.id),
    quantity: state.quantity.merge({
      [item.id]: 0,
    }),
    quantityTotal: state.quantityTotal.merge({
      [item.shop.id]: sum(cart.map(x => _.get(state.quantity, x.id, 0))),
    }),
    subtotal: state.subtotal.merge({
      [item.shop.id]: sum(
        cart.map((x) => x.price * _.get(state.quantity, x.id, 0)),
      ),
    }),
  });
};

export const resetCart = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CART_RECEIVED]: cartReceived,
  [Types.CARTS_RECEIVED]: cartsReceived,
  [Types.ADD_ITEM_TO_CART]: addItemToCart,
  [Types.UPDATE_CART]: updateCart,
  [Types.REMOVE_ITEM_FROM_CART]: removeItemFromCart,
  [Types.RESET_CART]: resetCart,
});
