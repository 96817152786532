import styled from "@emotion/styled";
import SearchRefinements from "Components/SearchRefinements";
import SearchResults from "Components/SearchResults";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 1200px;
  margin-top: 1.875rem;
`;

export type SearchContainerProps = {
  results: any[];
};

const SearchContainer: FC<SearchContainerProps> = ({ results }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <SearchRefinements />
      <SearchResults results={results} onUseThisProduct={() => navigate('/create')} />
    </Container>
  );
};

export default SearchContainer;
