import { FC } from "react";
import Header from "Components/Header";
import LeftNavigation from "Components/LeftNavigation";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";
import SummaryTitle from "Components/SummaryTitle";
import { useNavigate } from "react-router-dom";
import ShippingLabelsTable from "./ShippingLabelsTable";
import SearchShipping from "./SearchShipping";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const ShippingContainer = styled.div`
  width: 880px;
`;

const ShippingLabel: FC = () => {
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    navigate(`/search?q=${query}`);
  };
  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <LeftNavigation active="shipping" />
        <ShippingContainer>
          <SummaryTitle text="Track and manage your shipments" />
          <SearchShipping />
          <ShippingLabelsTable />
        </ShippingContainer>
        <Flex flex={1} />
      </Content>
    </div>
  );
};

export default ShippingLabel;
