import { Box, Button, Flex, Select, useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC, useState } from "react";
import FilterMenu from "../FilterMenu";
import {
  ChevronDownOutline,
  ChevronUpOutline,
  PlusOutline,
} from "@styled-icons/evaicons-outline";
import AddCustomItemSpecificModal from "./AddCustomItemSpecificModal";

const Container = styled.div`
  font-family: RockfordSansLight;
  min-height: 177px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
  font-family: RockfordSansRegular;
`;

const Subtitle = styled(Title)`
  font-family: RockfordSansMedium;
`;

const Text = styled.div`
  margin-bottom: 24px;
`;

const Required = styled.div`
  margin-bottom: 40px;
`;

const Additional = styled.div`
  margin-bottom: 40px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  margin-bottom: 15px;
`;

const ShowMoreButton = styled(Button)`
  height: 45px;
  background-color: #fff;
  color: #4724fe;
  padding-left: 0px;

  &:hover {
    background-color: #fff;
  }
`;

export type ItemSpecificsProps = {
  itemSpecifics: {
    required: any[];
    additional: any[];
  };
};

const ItemSpecifics: FC<ItemSpecificsProps> = ({ itemSpecifics }) => {
  const [showMore, setShowMore] = useState(true);
  const {
    isOpen: isAddCustomItemSpecificModalOpen,
    onOpen: onAddCustomItemSpecificModalOpen,
    onClose: onAddCustomItemSpecificModalClose,
  } = useDisclosure();
  const onShowMore = () => {
    setShowMore(!showMore);
  };
  const onSelect = (item: any) => {};
  const onSaveCustomItemSpecific = (name: string, value: string) => {
    onAddCustomItemSpecificModalClose();
  };
  return (
    <Container>
      <Title>ITEM SPECIFICS</Title>
      <Required>
        <Subtitle>Required</Subtitle>
        <Text>Buyers need these details to find your item.</Text>
        <Box>
          {itemSpecifics.required.map((item, index) => (
            <Row key={index}>
              <Flex width={352}>{item.name}</Flex>
              <Flex>
                <FilterMenu
                  value={item.value}
                  all={item.results}
                  onSelect={onSelect}
                />
              </Flex>
            </Row>
          ))}
        </Box>
      </Required>
      <Additional>
        <Subtitle>Additional</Subtitle>
        <Text>Buyers also search for these details.</Text>
        <Box> 
          {itemSpecifics.additional.map((item, index) => (
            <Row key={index}>
              <Flex width={352}>{item.name}</Flex>
              <Flex>
                <FilterMenu value={item.value} all={item.results} onSelect={onSelect} />
              </Flex>
            </Row>
          ))}
        </Box>
        {showMore && (
          <ShowMoreButton onClick={onShowMore}>
            <Box>Show more</Box>
            <Box>
              <ChevronDownOutline size={24} />
            </Box>
          </ShowMoreButton>
        )}
        {!showMore && (
          <>
            <Box>
              <Row>
                <Flex width={352}>Maximum Resolution</Flex>
                <Flex>
                  <FilterMenu
                    value="2560 x 1600"
                    all={["2560 x 1600", "1920 x 1200"]}
                    onSelect={onSelect}
                  />
                </Flex>
              </Row>
              <Box>
                <Button
                  onClick={onAddCustomItemSpecificModalOpen}
                  marginBottom={"28px"}
                >
                  <PlusOutline size={20} />
                  <Box marginLeft="5px">Custom item specific</Box>
                </Button>
              </Box>
            </Box>
            <ShowMoreButton onClick={onShowMore}>
              <Box>Show less</Box>
              <Box>
                <ChevronUpOutline size={24} />
              </Box>
            </ShowMoreButton>
          </>
        )}
      </Additional>
      <AddCustomItemSpecificModal
        isOpen={isAddCustomItemSpecificModalOpen}
        onClose={onAddCustomItemSpecificModalClose}
        onSave={onSaveCustomItemSpecific}
      />
    </Container>
  );
};

export default ItemSpecifics;
