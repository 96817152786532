
import { gql } from "@apollo/client";
import { USER_INFO_FRAGMENT } from "../fragments";

export const GET_USER_BY_ID = gql`    
${USER_INFO_FRAGMENT}
  query GetUserById($id: ID!) {
    user(id: $id) {
        id
        name
        stars
        positiveFeedback
    }
  }`;