import React, {FC} from 'react';
export type CartProps = {
  height?: number;
};

const CartPlaceholder: FC<CartProps> = props => (
  <svg height={250} width={250} viewBox="0 0 512 512" {...props}>
    <path
      d="m489 304.71-12 25.13a13.36 13.36 0 0 1-14.28 7.38l-2.8-.48C381.5 483.41 347.76 503.81 346.19 506 146.68 487.94 81.47 449.2 80.3 448.9c-21-54.44-42.29-127.52-48.75-186a13.28 13.28 0 0 1-9.86-12.87v-28a13.34 13.34 0 0 1 15.6-13.13l32.05 5.58c.37-9.52 1-19.15 1.74-28.74l1.56-19.09 17.47 13 3.11-3.5 8.35 4.75 8-6.69 9.67 8.59L134 170.21l.12.17-.36-.71c-11.54-7.41-37.37-27.76-50.83-68.13l-2.55-7.67 56.74-38.13c.61.88 31 50.88 55.79 62.31l5.38 2.48 3.56 29.3c20.35 8.27 46.49 16 55.27 36.74 7.26-17.18 13.17-30.62 17.68-40.17-2-14.84 1-24.51 9.05-28.76 7.83-4.15 18.55-7.8 27-5A96.59 96.59 0 0 1 333.39 78l21.23-72h116.69L405 116.55l-11.46 70.58a29.58 29.58 0 0 1-24.59 24.5c-26.3 4.07-26.46 4.45-30.32 4.48-.34 5.17-.7 10.6-1.1 16.26l121.18 8.43-7.75 40.11 28.34 4.93a13.33 13.33 0 0 1 9.7 18.87z"
      fill="#fff"
    />
    <path
      d="m239.85 243.3-4.55-2.07c4.42-9.75 12.51-31.62 10.88-42.25-1.08-7-6.1-14-13.77-19-14.32-9.46-33.68-11.09-47-23.81l3.45-3.62c12.29 11.71 31.57 13.5 46.35 23.25 8.84 5.85 14.66 14 16 22.46 1.73 11.79-6.43 34.17-11.36 45.04z"
      fill="#ff5800"
    />
    <path
      d="M306.47 317.75a12.53 12.53 0 0 0 14.68-11.29c3.27-39.38 12.15-150.88 8.9-162.92-3.95-14.65-30.94-15.36-30.94-15.36s23.09 4.63 19.48 41.4c-2.86 29.16-16.66 103.84-22.25 133.52a12.51 12.51 0 0 0 10.13 14.65z"
      fill="#021768"
    />
    <path
      d="m119.06 196.91-9.72-8.63-6.6 5.54-7.29-4.15L91.6 194l-9.93-7.37c-4.84 59.24-1.88 113.77 9.84 163l27.33-10.79s20.32-97.07.22-141.93z"
      fill="#d18e23"
    />
    <path d="M206.68 377.16h-.14c-.76-.23-76.26-22.9-115.08-27a.5.5 0 0 1-.45-.43c-.1-.75-.71 0 27.64-11.32.43-.11 36.4-8.3 63.24-2.6s54 19.06 54.31 19.19a.51.51 0 0 1 0 .91l-18.45 8.33L207.07 377a.5.5 0 0 1-.39.16zM93.56 349.39c38.13 4.47 107.65 25.11 113 26.7l10.54-12.58a.55.55 0 0 1 .17-.13l17.58-7.94c-5.07-2.41-29.29-13.58-53.12-18.65-26.62-5.66-62.37 2.49-62.73 2.57z" />
    <path
      d="M213.58 204.23c-.79 52.3 7.23 102.59 22.4 151.22-32.38-10.45-71.82-15.65-117.14-16.58l.22-142 13.07-11.16 9 12.35 13.36-5.31 1.41 7.52 15.1-8.4 11.94 9.9 5.22-3.74 12.21 8.5z"
      fill="#f49f5f"
    />
    <path
      d="m428.49 341.09 17.49-90.52-219.95-15.3-10.5 89.64z"
      fill="#43aa99"
    />
    <path
      d="M134 70.63s28.86 45.26 54.44 57.07l3.81 31.37-48.08 4.37S109.06 146.39 93 98.18z"
      fill="#fcc836"
    />
    <path
      d="m40.19 235.27 422.4 73.46s-73.46 145-120 186.28c0 0-177.75-15.75-253.83-53.79-.03 0-45.95-118.06-48.57-205.95z"
      fill="#4c3ce3"
    />
    <path
      d="M32.32 222.07v28a2.7 2.7 0 0 0 2.23 2.66c459.82 79.18 431.49 75.4 432.86 72.55l12-25.13a2.7 2.7 0 0 0-2-3.82l-442-76.89a2.68 2.68 0 0 0-3.09 2.63z"
      fill="#ff3d81"
    />
    <path
      d="M287 153.72s-6.12-22.52 1.75-26.68 16.18-6.12 19.89-3.71 10.5 17.27 10.5 17.27z"
      fill="#ffad92"
    />
    <path
      d="M236.59 301.66a12.54 12.54 0 0 1-8.13-16.66c14.59-36.73 56.21-140.54 64.47-149.88 10.06-11.37 34.55 0 34.55 0s-22.74-6.12-35.86 28.42C281.22 190.94 260.37 264 252.18 293a12.53 12.53 0 0 1-15.59 8.62z"
      fill="#8e1447"
    />
    <path
      d="m452.54 16.63-57.72 96.2L383 185.42a19 19 0 0 1-15.77 15.71l-26.07 4.14a16.56 16.56 0 0 1-13.16-3.59c-8.67-7.16-24.79-21.91-35.52-40.31 0 0 6.57-19.09 23.51-25.51 0 0 1.64-28.93 26.78-52.11l19.79-67.12z"
      fill="#ffad92"
    />
    <rect
      fill="#fff"
      height={41.14}
      rx={2.38}
      transform="rotate(-39.1 139.822 120.634)"
      width={7.76}
      x={136.01}
      y={100.14}
    />
    <circle cx={153.02} cy={119.07} fill="#fff" r={3.88} />
    <circle cx={126.32} cy={121.81} fill="#fff" r={3.88} />
    <g fill="#776cff">
      <path d="M401 368a1601.09 1601.09 0 0 1-307.69-61.79c-3.18-1-1.7-5.75 1.46-4.78a1596.6 1596.6 0 0 0 306.71 61.64c3.3.33 2.78 5.31-.48 4.93zM375.91 405.78A888.19 888.19 0 0 1 91 351.63c-3.12-1.14-1.38-5.83 1.72-4.69A883.3 883.3 0 0 0 376 400.78c3.33.08 3.21 5.08-.09 5zM345.78 448.93A759.32 759.32 0 0 1 99.11 407c-3.12-1.08-1.49-5.81 1.63-4.73a754.13 754.13 0 0 0 245.05 41.66 2.5 2.5 0 0 1 2.49 2.51 2.53 2.53 0 0 1-2.5 2.49z" />
      <path d="m303.39 463 65.05-121.74c1.56-2.92 6-.56 4.41 2.36L307.8 465.38c-1.56 2.92-5.97.62-4.41-2.38zM262.38 456.55c53-124.41 48.58-128.6 53.73-126.64a2.5 2.5 0 0 1 1.44 3.23c-16 41.91-33 84.09-50.57 125.37-1.3 3.04-5.9 1.08-4.6-1.96zM125 429.23a2.5 2.5 0 0 1-2.5-2.48l-1.2-130.23a2.55 2.55 0 0 1 2.5-2.53 2.5 2.5 0 0 1 2.5 2.48l1.17 130.23a2.52 2.52 0 0 1-2.48 2.53zM158.93 436.13 165 307c.15-3.3 5.15-3.07 5 .24l-6.08 129.15c-.15 3.28-5.15 3.05-4.99-.26zM197.84 442.4c5.08-41.79 8.65-84.3 10.63-126.35.16-3.3 5.16-3.07 5 .23-2 42.18-5.58 84.81-10.67 126.73-.4 3.28-5.36 2.67-4.96-.61zM230.67 450.85l30-125.19c.77-3.22 5.63-2.05 4.86 1.16l-30 125.2c-.77 3.21-5.64 2.05-4.86-1.17z" />
    </g>
  </svg>
);

export default CartPlaceholder;
