import { Link as DefaultLink, Stack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { HeartOutline } from "@styled-icons/evaicons-outline";
import { Star } from "@styled-icons/evaicons-solid";
import { FC } from "react";
import { useUserQuery } from "../../../Hooks/useUser";

interface SellerInformationProps {
  id: string
}
const Container = styled.div`
  width: 300px;
  padding: 9px 0 15px 15px;
`;

const Title = styled.h1`
  font-family: RockfordSansMedium;
  font-size: 18px;
`;

const Link = styled(DefaultLink)`
  color: #4724fe;
  font-size: 16px;
  &:hover {
    color: #4724fe !important;
  }
`;

const StarWrapper = styled.div`
  color: #4724fe;
  display: inline-block;
`;

const SaveThisSeller = styled.div`
  cursor: pointer;
  color: #4724fe;
  display: inline-block;
`;

const Feedback = styled.div`
  font-family: RockfordSansLight;
  margin-top: 6px;
`;

const SellerUsername = styled(Link)`
  margin-top: 6px;
`;

const Separator = styled.div`
  height: 25px;
`;

const Verified = styled.div`
  margin-top: 25px;
  font-family: RockfordSansLight;
  color: #38454e;
`;

const SellerInformation: FC<SellerInformationProps> = ({ id }) => {
  const { getUser } = useUserQuery(id)
  const onSaveThisSeller = () => {}
  return (
    <Container>
      <Title>Seller Information</Title>
      <SellerUsername href="#">{getUser?.user?.name}</SellerUsername>
      <StarWrapper style={{ marginLeft: 5 }}>(</StarWrapper>
      <Link href="#">
        {getUser?.user?.stars}
        <Star size={18} style={{ marginTop: -5 }} />
      </Link>
      <StarWrapper>)</StarWrapper>
      <Feedback>{`${getUser?.user?.positiveFeedback}% positive feedback`}</Feedback>
      <Separator />
      <Stack>
        <SaveThisSeller onClick={onSaveThisSeller}>
          <HeartOutline size={18} style={{ marginRight: 5 }} />
          Save this seller
        </SaveThisSeller>
        <Link href="#">Contact seller</Link>
        <Link href="#">Visit store</Link>
        <Link href="#">See other items</Link>
      </Stack>
      <Verified>Registered as a Business Seller</Verified>
    </Container>
  );
};

export default SellerInformation;
