import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const LIST_DATA = ['sausage'];

const ADDRESSES = [
  {
    id: '1',
    name: 'Arret Bus',
    details: 'Antananarivo',
  },
  {
    id: '2',
    name: 'Ankorondrano',
    details: 'Antananarivo',
  },
  {
    id: '3',
    name: 'Ambatobe',
    details: 'Antananarivo',
  },
];

const DELIVERY_OPTIONS = [
  {
    id: '1',
    name: 'Meet at door',
  },
  {
    id: '2',
    name: 'Meet outside',
  },
];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  addNewAddress: ['address'],
  removeAddress: ['address'],
  getAddress: ['id'],
  getAllAddresses: null,
  addressReceived: ['address'],
  addressesReceived: ['addresses'],
  selectDeliveryOption: ['deliveryOption'],
});

export const AddressTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  results: LIST_DATA,
  addresses: ADDRESSES,
  deliveryOptions: DELIVERY_OPTIONS,
  currentDeliveryOption: DELIVERY_OPTIONS[0],
});

/* ------------- Reducers ------------- */

export const addNewAddress = () => INITIAL_STATE;

export const removeAddress = () => INITIAL_STATE;

export const addressReceived = () => INITIAL_STATE;

export const addressesReceived = () => INITIAL_STATE;

export const selectDeliveryOption = (state, {deliveryOption}) => {
  return state.merge({currentDeliveryOption: deliveryOption});
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_NEW_ADDRESS]: addNewAddress,
  [Types.REMOVE_ADDRESS]: removeAddress,
  [Types.ADDRESS_RECEIVED]: addressReceived,
  [Types.ADDRESSES_RECEIVED]: addressesReceived,
  [Types.SELECT_DELIVERY_OPTION]: selectDeliveryOption,
});
