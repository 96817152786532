import { Container, List as DefaultList, ListItem } from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";

import styled from "@emotion/styled";
import AppInput from "../../Components/AppInput";
import Footer from "Components/Footer";
import { formatAmount } from "Lib/format";
import Button from "Components/Button";
import { ChevronRight } from "@styled-icons/evil";
import { PlusOutline } from "@styled-icons/evaicons-outline";
import { Tag } from "@styled-icons/bootstrap";

const StyledContainer = styled(Container)`
  display: flex;
  min-height: calc(100vh - 580px);
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 25px;
`;

const Summary = styled.div`
  display: flex;
  align-self: flex-start;
  flex-direction: column;

  margin: 12px;
  min-width: 300px;
  padding: 12px;
  flex-shrink: 0;
`;

const Title = styled.h3`
  font-family: RockfordSansMedium;
  font-size: 18px;
`;

const Subtotal = styled.div`
  font-family: RockfordSansLight;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DeliveryFee = styled.div`
  font-family: RockfordSansLight;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Total = styled.div`
  font-family: RockfordSansMedium;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InputGroup = styled.div`
  flex-direction: column;
  display: flex;
  margin: 8px;
`;

const List = styled(DefaultList)`
  margin-bottom: 25px;
`;

const Item = styled.div``;

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  cursor: pointer;
`;

const MVola = styled.img`
  height: 30px;
`;

const AddShippingAddress = styled.div`
  font-family: RockfordSansMedium;
  color: #4724fe;
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px;
`;

const Text = styled.div``;

const SubTitle = styled(Text)`
  font-size: 18px;
  font-family: RockfordSansMedium;
  margin-bottom: 10px;
`;

const Amount = styled.div``;

const AddPromo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Checkout: FC = () => {
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    if (query === "") {
      navigate("/categories");
      return;
    }
  };

  const subtotal = 100000;
  const deliveryFee = 2000;
  const currency = "Ar";

  return (
    <Container maxW="container.xl">
      <Header onSearch={onSearch} onlySingIn={true} pageTitle="Checkout" />
      <StyledContainer maxW="container.lg">
        <LeftSide>
          <Title>Delivery details</Title>
          <AddShippingAddress>
            <div>Add shipping address</div>
            <PlusOutline size={30} color="#4724fe" />
          </AddShippingAddress>
          <SubTitle>Your items</SubTitle>
          <List>
            <ListItem>
              <Item>NodeJS Icon Zipped Hoodie</Item>
            </ListItem>
          </List>
          <AddPromo>
            <Row>
              <Tag size={28} />
              <Text style={{ marginLeft: 15 }}>Add a promo</Text>
            </Row>
            <ChevronRight size={40} style={{ marginRight: -5 }} />
          </AddPromo>
        </LeftSide>
        <Summary>
          <Subtotal>
            <Text>Subtotal</Text>
            <Amount>
              {formatAmount(subtotal)} {currency}
            </Amount>
          </Subtotal>
          <DeliveryFee>
            <Text>Delivery fee</Text>
            <Amount>
              {formatAmount(deliveryFee)} {currency}
            </Amount>
          </DeliveryFee>
          <Total>
            <Text>Total</Text>
            <Amount>
              {formatAmount(subtotal + deliveryFee)} {currency}
            </Amount>
          </Total>
          <PaymentMethod>
            <MVola src={require("Assets/pictures/mvola.webp")} />
            <ChevronRight size={40} style={{ marginRight: -10 }} />
          </PaymentMethod>
          <Button width="100%" label="" primary rounded="full">
            Pay &middot; {formatAmount(subtotal + deliveryFee)} {currency}
          </Button>
        </Summary>
      </StyledContainer>
      <Footer />
    </Container>
  );
};

export default Checkout;
