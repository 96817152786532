import { FC } from "react";
import Header from "Components/Header";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  padding-top: 100px;
  margin: 0 auto;
  width: 1200px;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
`;

const Order: FC = () => {
  
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    if (query === "") {
      navigate("/categories");
      return;
    }
    navigate(`/search?q=${query}`);
  };
  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <Title>Order</Title>
      </Content>
    </div>
  );
};

export default Order;
