import { List as DefaultList, ListItem } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { Link as DefaultLink} from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 180px);
  justify-content: flex-end;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const List = styled(DefaultList)`
  margin-right: 25px;
`;

const Link = styled(DefaultLink)<{ isActive?: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    ${({ isActive }) =>
      isActive &&
      css`
        color: #4724fe !important;
      `}
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: #4724fe;
      background-color: #3007ff0d;
    `}
`;

export type LeftNavigationProps = {
  active: string;
};

const LeftNavigation: FC<LeftNavigationProps> = ({ active }) => {
  return (
    <Container>
      <List spacing={5} width={250}>
        <ListItem>
          <Link to="/dashboard" isActive={active === "overview"}>
            Overview
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/sell" isActive={active === "sell"}>
            Sell an item
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/drafts" isActive={active === "drafts"}>
            Drafts
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/scheduled" isActive={active === "scheduled"}>
            Scheduled
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/active" isActive={active === "active"}>
            Active
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/sold" isActive={active === "sold"}>
            Sold
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/unsold" isActive={active === "unsold"}>
            Unsold
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/payments" isActive={active === "payments"}>
            Payments
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/shipping" isActive={active === "shipping"}>
            Shipping labels
          </Link>
        </ListItem>
      </List>
    </Container>
  );
};

export default LeftNavigation;
