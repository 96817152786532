import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// import { getRemoteConfig } from "firebase/remote-config";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPJO7YyFl8Kk_-UdiurA6bgjz4O5UhzG0",
  authDomain: "marketplace-43e4a.firebaseapp.com",
  projectId: "marketplace-43e4a",
  storageBucket: "marketplace-43e4a.appspot.com",
  messagingSenderId: "383651159647",
  appId: "1:383651159647:web:390c3a48509349382685ea",
  measurementId: "G-7Y7CQHFQNZ",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
// export const remoteConfig = getRemoteConfig(app);
export const googleProvider = new GoogleAuthProvider();

// remoteConfig.settings.minimumFetchIntervalMillis = 3600000;