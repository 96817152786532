import { FC } from "react";
import styled from "@emotion/styled";
import { Button as DefaultButton } from "@chakra-ui/react";

const Container = styled.div`
  width: 880px;
  height: 124px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.a`
  color: #4724fe;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const Button = styled(DefaultButton)`
  background-color: #23fdd8;
  color: #2c398f;
  width: 80%;
  border-radius: 20px;
`;

const Balance = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Numbers = styled.div`
  font-size: 1.5rem;
  text-align: center;
`;

export type SellingActivityProps = {
  onListAnItem: () => void;
}

const SellingActivity: FC<SellingActivityProps> = ({ onListAnItem }) => {
  return (
    <Container>
      <Flex>
        <Flex>
          <div>
            <Numbers>0</Numbers>
            <Label href="/active">Active</Label>
          </div>
        </Flex>
        <Flex>
          <div>
            <Numbers>0</Numbers>
            <Label href="/sold">Sold</Label>
          </div>
        </Flex>
        <Flex>
          <div>
            <Numbers>0</Numbers>
            <Label href="/unsold">Unsold</Label>
          </div>
        </Flex>
      </Flex>
      <Flex>
        <Balance>0.00 Ar</Balance>
      </Flex>
      <Flex>
        <Button onClick={onListAnItem}>List an item</Button>
      </Flex>
    </Container>
  );
};
export default SellingActivity;
