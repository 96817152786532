import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartActions from "Redux/CartRedux";
import _ from "lodash";
import styled from "@emotion/styled";
import { Link as DefaultLink, ButtonProps, Box } from "@chakra-ui/react";
import { formatAmount } from "Lib/format";

const SellerItemImage = styled.img`
  width: 100px;
  height: 100px;
`;

const Item = styled(Box)`
  display: flex;
  padding: 10px;
  margin: 6px 10px 4px 10px;
  padding-left: 0;
  border-radius: 6px;
  justify-content: space-between;
`;
const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  flex-shrink: 2;
  justify-content: center;
`;

const ItemName = styled.div`
  font-family: RockfordSansMedium;
  font-size: 18px;
  font-weight: 500;
`;

const ItemCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px;
  margin: 4px;
  margin-left: 15px;
`;

const ImageWrapper = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  padding: 6px;
`;

const Link = styled(DefaultLink)`
  color: #4724fe;
  font-size: 16px;
  &:hover {
    color: #4724fe !important;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const RoundedButton = styled.button`
  width: 45px !important;
  height: 45px !important;
  border-radius: 50%;
  background-color: #e0dddd7b;
  font-size: 24px;
`;

const Numbers = styled.div`
  font-size: 16px;
  text-align: center;
  font-family: RockfordSansMedium;
  margin: 0 4px 0 4px;
  color: #4724fe;
`;

const Label = styled.div`
  font-family: RockfordSansLight;
  margin-right: 4px;
`;

const CounterContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
`;

const ProductPrice = styled.div`
  display: flex;
  flex-shrink: 2;
  font-size: 20px;
  align-self: center;
  font-family: RockfordSansMedium;
`;

export type CartItemProps = {
  item: any;
};

const CartItem: FC<CartItemProps> = ({ item }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart);
  const [quantity, setQuantity] = useState<number>(
    _.get(cart, `quantity.${item.id}`, 1) || 1
  );

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
    updateCart(quantity + 1);
  };
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      updateCart(quantity - 1);
    }
  };

  const updateCart = (x: number) => {
    dispatch(CartActions.updateCart(item, x));
    // navigation.goBack();
  };

  const removeItem = () => {
    dispatch(CartActions.removeItemFromCart(item));
  };

  return (
    <Item>
      <ImageWrapper>
        <SellerItemImage
          src={item.image}
        />
      </ImageWrapper>
      <ItemInfo>
        <ItemName>{item.name}</ItemName>
        {
          // <Label>Black</Label>
        }
      </ItemInfo>
      <CounterContainer>
        <Label>Quantity</Label>
        <ItemCounter>
          <RoundedButton onClick={decrementQuantity}>-</RoundedButton>
          <Numbers>{quantity}</Numbers>
          <RoundedButton onClick={incrementQuantity}>+</RoundedButton>
        </ItemCounter>
      </CounterContainer>
      <PriceContainer>
        <ProductPrice>
          {formatAmount(item.price * cart.quantity[item.id])} {"Ar"}
        </ProductPrice>
        <Link onClick={removeItem}>Remove</Link>
      </PriceContainer>
    </Item>
  );
};

export default CartItem;
