import _, { sum } from "lodash";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CartDetails from "./CartDetails";

const CartDetailsWithData: FC = () => {
  const { currentCart, quantity, quantityTotal, shops } = useSelector(
    (state: any) => state.cart
  ) as any;
  const { id: shopId } = useParams<"id">() as { id: string };
  const subtotal = sum(
    _.get(currentCart, shopId, []).map(
      (item: any) => item.price * quantity[item.id]
    )
  );
  const count = _.defaultTo(sum(Object.values(quantityTotal)), 0);
  
  const shop = _.get(shops, shopId, {});
  const currency = "Ar";
  return (
    <CartDetails
      data={_.get(currentCart, shopId, [])}
      quantity={quantity}
      subtotal={subtotal}
      currency={currency}
      shopName={shop.name}
      count={count}
    />
  );
};

export default CartDetailsWithData;
