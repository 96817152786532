import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const LIST_DATA = [
  {id: 1, name: 'All', active: true},
  {id: 2, name: 'Apparel'},
  {id: 3, name: 'Electronics'},
  {id: 4, name: 'Furniture'},
  {id: 5, name: 'Home & Garden'},
  {id: 6, name: 'Jewelry'},
  {id: 7, name: 'Sports & Outdoors'},
];

const POPULAR = [
  {
    id: 1,
    name: 'Smartphones',
    color: 'tomato',
    cover: require('Images/smartphones.webp'),
  },
  {
    id: 2,
    name: 'Gaming',
    color: '#b794f4',
    cover: require('Images/gaming.webp'),
  },
  {
    id: 3,
    name: 'Clothing',
    color: '#bee3f8',
    cover: require('Images/clothing.webp'),
  },
];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  selectCategory: ['category'],
  getCategory: ['id'],
  getAllCategories: null,
  categoryReceived: ['categories'],
  categoriesReceived: ['categories'],
});

export const CategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: LIST_DATA,
  currentCategory: LIST_DATA[0],
  popular: POPULAR,
});

/* ------------- Reducers ------------- */

export const categoryReceived = () => INITIAL_STATE;

export const selectCategory = (state, {category}) => {
  return state.merge({currentCategory: category});
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CATEGORY_RECEIVED]: categoryReceived,
  [Types.CATEGORIES_RECEIVED]: categoryReceived,
  [Types.SELECT_CATEGORY]: selectCategory,
});
