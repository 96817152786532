import { FC } from "react";
import { useSelector } from "react-redux";
import ShoppingCarts from "./ShoppingCarts";

export type ShoppingCartsWithDataProps = {
  counter: number;
  carts?: any[];
};

const ShoppingCartsWithData: FC<ShoppingCartsWithDataProps> = ({ counter }) => {
  const { shops } = useSelector((state: any) => state.cart) as any;
  const { currentCart, quantityTotal, subtotal } = useSelector(
    (state: any) => state.cart
  ) as any;

  return (
    <ShoppingCarts
      counter={counter}
      currentCart={currentCart}
      quantityTotal={quantityTotal}
      subtotal={subtotal}
      shops={shops}
    />
  );
};

export default ShoppingCartsWithData;
