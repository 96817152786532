import {
  Box,
  Button,
  Input as DefaultInput,
  InputGroup as DefaultInputGroup,
  InputLeftElement,
  Modal,
  ModalBody as DefaultModalBody,
  ModalContent as DefaultModalContent,
  ModalHeader as DefaultModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, useState } from "react";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";

const ModalHeader = styled(DefaultModalHeader)`
  font-family: RockfordSansMedium;
  font-weight: normal;
  text-align: center;
  margin-right: 40px;
`;

const ModalContent = styled(DefaultModalContent)`
  height: 87%;
  overflow-y: auto;
  padding: 16px 32px 0;
  padding-top: 0;
`;

const ModalBody = styled(DefaultModalBody)`
  padding: 0;
`;

const Input = styled(DefaultInput)`
  background-color: #f9f9f9;
  width: 225px;
  &:focus {
    background-color: #fff;
  }
`;

const InputGroup = styled(DefaultInputGroup)`
  width: 225px;
  margin-right: 8px;
`;

const RadioText = styled.div`
  font-family: RockfordSansMedium;
  font-size: 16px;
`;

const CheckboxText = styled.div`
  font-family: RockfordSansMedium;
`;

const Tips = styled.div<{ marginBottom?: string }>`
  font-family: RockfordSansLight;
  font-size: 15px;
  color: gray;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `};
`;

const InputWrapper = styled.div`
  margin-left: 25px !important;
  -webkit-margin-start: 48px !important;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  margin-left: 25px !important;
  -webkit-margin-start: 25px !important;
  font-size: 0.875rem;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 18px;
  color: #4724fe;
  font-weight: 400;
`;

const MinimumOfferAmount: FC = () => {
  const [checked, setChecked] = useState(false);
  return (
    <CheckboxWrapper>
      <Checkbox
        checked={checked}
        onChange={(e) => setChecked((e.target as any).checked)}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        <CheckboxText>Minimum offer amount</CheckboxText>
      </Checkbox>
    </CheckboxWrapper>
  );
};

const AutoAcceptAmount: FC = () => {
  const [checked, setChecked] = useState(false);
  return (
    <CheckboxWrapper>
      <Checkbox
        checked={checked}
        onChange={(e) => setChecked((e.target as any).checked)}
        labelPlacement={LABEL_PLACEMENT.right}
      >
        <CheckboxText>Auto-accept amount</CheckboxText>
      </Checkbox>
    </CheckboxWrapper>
  );
};

export type OffersModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const OffersModal: FC<OffersModalProps> = ({ isOpen, onClose }) => {
  const [value, setValue] = useState("1");
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Offers</ModalHeader>
        <CloseButton variant={"unstyled"} onClick={onClose}>
          Done
        </CloseButton>
        <ModalBody>
          <Tips marginBottom="32px">
            Buyers interested in your item can make you offers -- you can
            accept, counter or decline.
          </Tips>
          <RadioGroup
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            name="number"
            align={ALIGN.vertical}
          >
            <Radio value={"1"} overrides={styles.radio}>
              <RadioText>Allow offers</RadioText>
              <Tips>
                See all offers, or if you want, set up rules for when you'll
                review or accept offers.
              </Tips>
            </Radio>
            <MinimumOfferAmount />
            <InputWrapper>
              <InputGroup>
                <InputLeftElement color="gray.400" children="Ar" />
                <Input paddingLeft={"40px"} />
              </InputGroup>
              <Tips>and above</Tips>
            </InputWrapper>
            <AutoAcceptAmount />
            <InputWrapper>
              <InputGroup>
                <InputLeftElement color="gray.400" children="Ar" />
                <Input paddingLeft={"40px"} />
              </InputGroup>
              <Tips>and above</Tips>
            </InputWrapper>
            <Box height="32px" />
            <Radio value={"2"} overrides={styles.radio}>
              <RadioText>Don't allow offers</RadioText>
              <Tips>
                Tip: Letting buyers make offers can increase your chance of
                selling.
              </Tips>
            </Radio>
          </RadioGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const styles = {
  radio: {
    Root: {
      style: () => ({
        alignItems: "flex-start",
      }),
    },
  },
};

export default OffersModal;
