import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const POPULAR = [
  {
    id: 1,
    name: 'Apple',
    color: '#feebc8',
    cover: require('Images/apple.webp'),
  },
  {
    id: 2,
    name: 'Sony',
    color: '#c6f6d5',
    cover: require('Images/sony.webp'),
  },
  {
    id: 3,
    name: 'Samsung',
    color: '#FED7E2',
    cover: require('Images/samsung.webp'),
  },
];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  brandsReceived: ['brands'],
});

export const BrandTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  popular: POPULAR,
});

/* ------------- Reducers ------------- */

export const brandsReceived = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BRANDS_RECEIVED]: brandsReceived,
});
