import { FC, useState } from "react";
import Header from "Components/Header";
import LeftNavigation from "Components/LeftNavigation";
import styled from "@emotion/styled";
import { ButtonProps, Flex, useDisclosure } from "@chakra-ui/react";
import SummaryTitle from "Components/SummaryTitle";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from "baseui/tabs-motion";
import Button from "Components/Button";
import { PlusOutline } from "@styled-icons/evaicons-outline";
import AddPaymentOptionsModal from "./AddPaymentOptionsModal";
import RecentTransactions from "./RecentTransactions";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const AddPaymentOption = styled(Button)<ButtonProps>`
  height: 200px;
  width: 485px;
  background-color: #fff;
  margin-top: 30px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: #fff;
  }
`;

const ButtonText = styled.div`
  font-family: RockfordSansLight;
  color: #4724fe;
  margin-top: 15px;
`;

const RightPane = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Payments: FC = () => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("0");
  const onSearch = (query: string) => {
    navigate(`/search?q=${query}`);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <LeftNavigation active="payments" />
        <Flex width={880} flexDirection="column">
          <SummaryTitle text="Payments" />
          <Tabs
            onChange={({ activeKey }) => {
              setActiveKey(activeKey as string);
            }}
            activeKey={activeKey}
            overrides={{
              TabHighlight: {
                style: () => ({
                  outline: "1px #4724fe solid",
                  backgroundColor: "#4724fe",
                }),
              },
            }}
          >
            <Tab
              title="Recent transactions"
              overrides={{
                TabPanel: {
                  style: () => ({
                    paddingLeft: 0,
                    paddingRight: 0,
                  }),
                },
              }}
            >
              <RecentTransactions />
            </Tab>
            <Tab
              title="Payment options"
              overrides={{
                TabPanel: {
                  style: () => ({
                    paddingLeft: 0,
                    paddingRight: 0,
                  }),
                },
              }}
            >
              <AddPaymentOption label="" onClick={() => onOpen()}>
                <PlusOutline size={48} color="#4724fe" />
                <ButtonText>Add payment option</ButtonText>
              </AddPaymentOption>
              <AddPaymentOptionsModal isOpen={isOpen} onClose={onClose} />
            </Tab>
          </Tabs>
        </Flex>
        <RightPane />
      </Content>
    </div>
  );
};

export default Payments;
