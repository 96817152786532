import { Button as DefaultButton, useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import PreviewListingModal from "./PreviewListingModal";

const CancelButton = styled(DefaultButton)`
  height: 45px;
  width: 180px;
  background-color: #fff;
  color: #4724fe;
  margin-right: 24px;
  &:hover {
    background-color: #fff;
  }
`;

const Button = styled(DefaultButton)`
  width: 180px;
  background-color: #4724fe;
  color: #fff;
  margin-right: 20px;
  height: 45px;
`;

const InvertedButton = styled(DefaultButton)`
  width: 180px;
  color: #4724fe;
  background-color: #fff;
  border: 1px solid rgba(221, 221, 221, 0.5);
  margin-right: 20px;
  height: 45px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  align-items: center;
  padding: 25px;
`;

export type ActionBarProps = {
  onListItem: () => void;
  onPreview: () => void;
  onSaveForLater: () => void;
  onCancel: () => void;
};

const ActionBar: FC<ActionBarProps> = ({
  onListItem,
  onSaveForLater,
  onCancel,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Container>
      <Button onClick={onListItem}>List item</Button>
      <InvertedButton onClick={() => onOpen()}>Preview</InvertedButton>
      <InvertedButton onClick={onSaveForLater}>Save for later</InvertedButton>
      <CancelButton onClick={onCancel}>Cancel</CancelButton>
      <PreviewListingModal isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};

export default ActionBar;
