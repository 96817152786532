import styled from "@emotion/styled";
import { FC, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Container = styled.div`
  min-height: 177px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
`;

export type DescriptionProps = {};

const Description: FC<DescriptionProps> = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState: EditorState) =>
    setEditorState(editorState);
  return (
    <Container>
      <Title>DESCRIPTION</Title>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
    </Container>
  );
};

export default Description;
