import styled from "@emotion/styled";
import Header from "Components/Header";
import Filter from "Components/Filter";
import { CloseOutline } from "@styled-icons/evaicons-outline";
import { FC } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem as DefaultGridItem,
  List,
  ListItem,
} from "@chakra-ui/react";
import Footer from "Components/Footer";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-top: 100px;
  min-height: calc(100vh - (148px + 400px));
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const Filters = styled(Row)`
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Clear = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(106, 115, 131);
  &:hover {
    color: #000;
  }
`;

const GridItem = styled(DefaultGridItem)`
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  min-height: 278px;
  cursor: pointer;
`;

const ItemTitle = styled.h2`
  margin-bottom: 0.5rem;
`;

const ImageWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const Image = styled.img`
  max-width: 154px;
`;

const Dl = styled.dl`
  display: table-row;
  font-family: RockfordSansLight;
`;

const Dt = styled.dt`
  display: table-cell;
  width: 140px;
`;

const LeftNavigation: FC = () => {
  return (
    <List width={"250px"}>
      <ListItem>test</ListItem>
    </List>
  );
};

const Featured: FC = () => {
  const navigate = useNavigate();

  const onSearch = () => {};

  const results = [
    {
      id: "1",
      name: "Apple MacBook Air 13in (256GB SSD, M1, 8GB) Laptop - Space Gray - MGN63LL/A (November, 2020)",
      image: require("Assets/pictures/s-l640.jpg"),
    },
    {
      id: "2",
      name: `Apple MacBook Pro 13" (256GB SSD, Intel Core i5 8th Gen., 1.4 GHz, 8GB) Laptop - Space Gray - MUHP2LL/A (July, 2019)`,
      image: require("Assets/pictures/s-l640 (1).jpg"),
    },
    {
      id: "3",
      name: `Apple MacBook Pro 13in (256GB SSD, M1, 8GB) Laptop - Space Gray - MYD82LL/A (November, 2020)`,
      image: require("Assets/pictures/s-l640 (2).jpg"),
    },
    {
      id: "4",
      name: `Apple MacBook Pro A1278 13.3" Laptop - MC700LL/A (February, 2011)`,
      image: require("Assets/pictures/s-l640 (3).jpg"),
    },
    {
      id: "5",
      name: `Apple MacBook Pro 13.3" (128GB, Intel Core i5, 2.7Ghz, 16GB RAM) Laptop - ‎MF839LL/A`,
      image: require("Assets/pictures/s-l640 (4).jpg"),
    },
    {
      id: "6",
      name: `Apple MacBook Air 13.3" M1 8GB 256GB SSD - Mgn63ll/a 2020, Gold`,
      image: require("Assets/pictures/s-l640 (5).jpg"),
    },
  ];

  const onClickItem = (id: string | null) => {
    id = "8caRLIqgqSyPf7nF";
    navigate(`/item/${id}`);
  };

  return (
    <div>
      <Header onSearch={onSearch} />
      <Container>
        <LeftNavigation />
        <Flex flex={1} justifyContent={"flex-start"} flexDirection="column">
          <Filters>
            <Row>
              <Filter
                label="Year"
                onApply={() => {}}
                options={[
                  "is equal to",
                  "is between",
                  "is greater than",
                  "is less than",
                ]}
              />
              <Filter
                label="Price"
                onApply={() => {}}
                options={[
                  "is equal to",
                  "is between",
                  "is greater than",
                  "is less than",
                ]}
              />
              <Filter
                label="Brand"
                onApply={() => {}}
                options={["Apple"]}
                multi
              />
            </Row>
            <Clear>
              <IconWrapper>
                <CloseOutline size={20} />
              </IconWrapper>
              <Box fontSize={14} marginTop={"-2px"}>
                Clear Filters
              </Box>
            </Clear>
          </Filters>

          <Grid templateColumns={"repeat(2, 1fr)"} marginTop="2rem">
            {results.map((item) => (
              <GridItem
                onClick={() => onClickItem(item.id)}
                key={item.id}
                w="100%"
              >
                <ImageWrapper>
                  <Image alt="" src={item.image} />
                </ImageWrapper>
                <Box>
                  <ItemTitle>{item.name}</ItemTitle>
                  <Dl>
                    <Dt>SDD Capacity</Dt>
                    <dd>250 GB</dd>
                  </Dl>
                  <Dl>
                    <Dt>Connectivity</Dt>
                    <dd>DisplayPort, USB-C, USB 3.1</dd>
                  </Dl>
                  <Dl>
                    <Dt>Brand</Dt>
                    <dd>Apple</dd>
                  </Dl>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Flex>
      </Container>
      <Footer />
    </div>
  );
};

export default Featured;
