import { useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import ItemCategoryModal from "./ItemCategoryModal";

const Container = styled.div`
  min-height: 177px;
  font-family: RockfordSansLight;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
  font-family: RockfordSansRegular;
`;

const SelectedCategory = styled.div`
  color: #4724fe;
  cursor: pointer;
  font-family: RockfordSansRegular;
`;

const Category = styled.div``;

export type ItemCategoryProps = {};

const ItemCategory: FC<ItemCategoryProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Container>
      <Title>ITEM CATEGORY</Title>
      <SelectedCategory onClick={onOpen}>Apple Laptops</SelectedCategory>
      <Category>
        {"in Computers/Tablets & Networking > Laptops & Netbooks"}
      </Category>
      <ItemCategoryModal isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};

export default ItemCategory;
