import { gql } from "@apollo/client";

export const GET_FEATURES_BRANDS = gql`
   query GetFeaturedBrands{
        brands {
            id
            name
            image
            createdAt
            updatedAt
        }
    }
`