import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Tab, Tabs } from "baseui/tabs-motion";
import Footer from "Components/Footer";
import Header from "Components/Header";
import { FC, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useBrandsQuery } from "../../Hooks/useBrands";
import { useCategoriesQuery } from "../../Hooks/useCategories";
import { useProductsQuery } from "../../Hooks/useProducts";
import { brandsCardBgColors, categoryCardBgColors } from "../../utils/mapColor";
import { ArrowRight } from "@styled-icons/remix-line";

const Title = styled.h2`
  font-size: 24px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const TabContent = styled.div`
  padding-top: 32px;
`;

const Explore = styled(Box)`
  height: 400px;
`;

const Card = styled(Box)`
  height: 300px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-size: 18px;
  margin-top: 5px;
  font-family: RockfordSansMedium;
`;

const Picture = styled.img`
  max-width: 100%;
  border-radius: 5px;
`;

const ProductLabel = styled.div`
  font-size: 14px;
  font-family: RockfordSansMedium;
  margin-top: 10px;
`;

const ProductPrice = styled.div`
  font-size: 16px;
  font-family: RockfordSansMedium;
  margin-top: 5px;
`;

const Shop = styled.div`
  font-family: RockfordSansLight;
  font-size: 14px;
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  width: calc(100vw - 30%);
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 1300px) {
    width: calc(100% - 100px);
  }
  @media (max-width: 1500px) {
    width: 80%;
  }
`;

const SeeAll = styled(Link)`
  font-family: RockfordSansMedium;
  font-size: 17px;
  display: flex;
  align-items: center;
`;

const Home: FC = () => {
  const [activeKey, setActiveKey] = useState("home");
  const navigate = useNavigate();
  const { getFeaturedProducts } = useProductsQuery();
  const { getFeaturedCategories } = useCategoriesQuery();
  const { getFeaturedBrands } = useBrandsQuery();
  const onSearch = (query: string) => {
    if (query === "") {
      navigate("/categories");
      return;
    }
    navigate(`/search?q=${query}`);
  };

  const onClickItem = (id: string | null) => {
    navigate(`/item/${id}`);
  };
  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <Tabs
          activeKey={activeKey}
          onChange={({ activeKey }) => setActiveKey(String(activeKey))}
          overrides={{
            Root: {
              style: () => ({
                width: "calc(100vw - 30%)",
              }),
            },
            TabHighlight: {
              style: () => ({
                outline: `#4724fe solid`,
                backgroundColor: "#4724fe",
                height: "2px",
              }),
            },
            TabBorder: {
              style: () => ({
                height: "3px",
              }),
            },
          }}
        >
          <Tab key="home" title="Home">
            <TabContent>
              <Explore>
                <Flex justifyContent={"space-between"} alignItems="center">
                  <Title>Explore Popular Brands</Title>
                  <SeeAll to="/brands">
                    <span>See All</span>
                    <ArrowRight size={18} style={{ marginLeft: 5 }} />
                  </SeeAll>
                </Flex>
                <Grid
                  templateColumns={"repeat(3, 1fr)"}
                  gap="32px"
                  mt="32px"
                  mb="32px"
                >
                  {getFeaturedBrands?.brands?.map((i, index) => (
                    <GridItem key={i?.id}>
                      <Link to={`/brands/${i?.id}`}>
                        <Card bg={brandsCardBgColors[index]}>
                          <img
                            style={{ maxHeight: 200 }}
                            src={i?.image}
                            alt="featured brand"
                          />
                        </Card>
                        <Label>{i?.name}</Label>
                      </Link>
                    </GridItem>
                  ))}
                </Grid>
              </Explore>
              <Explore mt={"48px"}>
                <Flex justifyContent={"space-between"} alignItems="center">
                  <Title>Explore Popular Categories</Title>
                  <SeeAll to="/categories">
                    <span>See All</span>
                    <ArrowRight size={18} style={{ marginLeft: 5 }} />
                  </SeeAll>
                </Flex>
                <Grid
                  templateColumns={"repeat(3, 1fr)"}
                  gap="32px"
                  mt="32px"
                  mb="32px"
                >
                  {getFeaturedCategories?.categories?.map((i, index) => (
                    <GridItem key={i?.id}>
                      <Link to={`/categories/${i?.id}`}>
                        <Card bg={categoryCardBgColors[index]}>
                          <img
                            style={{ maxWidth: 245 }}
                            src={i?.image}
                            alt="featured category"
                          />
                        </Card>
                        <Label>{i?.name}</Label>
                      </Link>
                    </GridItem>
                  ))}
                </Grid>
              </Explore>
              <Explore mt={"48px"}>
                <Flex justifyContent={"space-between"} alignItems="center">
                  <Title>Featured Products</Title>
                  <SeeAll to="/featured">
                    <span>See All</span>
                    <ArrowRight size={18} style={{ marginLeft: 5 }} />
                  </SeeAll>
                </Flex>
                <Grid
                  templateColumns={"repeat(5, 1fr)"}
                  gap="16px"
                  mt="32px"
                  mb="32px"
                >
                  {getFeaturedProducts?.products?.map((i) => (
                    <GridItem
                      bg="gray.50"
                      height="220px"
                      cursor={"pointer"}
                      key={i?.id}
                      onClick={() => onClickItem(i?.id || "")}
                    >
                      <Picture src={i?.image} />
                      <ProductLabel>{i?.name}</ProductLabel>
                      <Shop>{i?.seller.name}</Shop>
                      <ProductPrice>{i?.price}</ProductPrice>
                    </GridItem>
                  ))}
                </Grid>
              </Explore>
            </TabContent>
          </Tab>
          <Tab key="fashion" title="Fashion">
            <TabContent>
              <Box minHeight={"calc(100vh - 200px)"}></Box>
            </TabContent>
          </Tab>
          <Tab key="beauty" title="Beauty">
            <TabContent>
              <Box minHeight={"calc(100vh - 200px)"}></Box>
            </TabContent>
          </Tab>
          <Tab key="electronics" title="Electronics">
            <TabContent>
              <Box minHeight={"calc(100vh - 200px)"}></Box>
            </TabContent>
          </Tab>
          <Tab key="home-garden" title="Home & Garden">
            <TabContent>
              <Box minHeight={"calc(100vh - 200px)"}></Box>
            </TabContent>
          </Tab>
          <Tab key="sports" title="Sports">
            <TabContent>
              <Box minHeight={"calc(100vh - 200px)"}></Box>
            </TabContent>
          </Tab>
          <Tab key="motors" title="Motors">
            <TabContent>
              <Box minHeight={"calc(100vh - 200px)"}></Box>
            </TabContent>
          </Tab>
        </Tabs>
      </Content>
      <Footer />
    </div>
  );
};

export default Home;
