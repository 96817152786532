import {
  Button,
  Modal,
  ModalBody as DefaultModalBody,
  ModalContent as DefaultModalContent,
  ModalHeader as DefaultModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { FC, useState } from "react";

const ModalHeader = styled(DefaultModalHeader)`
  font-family: RockfordSansMedium;
  font-weight: normal;
  text-align: center;
  margin-right: 40px;
`;

const ModalContent = styled(DefaultModalContent)`
  height: 87%;
  overflow-y: auto;
  padding: 16px 32px 0;
  padding-top: 0;
`;

const ModalBody = styled(DefaultModalBody)`
  padding: 0;
`;

const RadioText = styled.div`
  font-family: RockfordSansMedium;
  font-size: 17px;
`;

const Tips = styled.div`
  font-family: RockfordSansLight;
  font-size: 15px;
  color: gray;
  margin-bottom: 32px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 18px;
  color: #4724fe;
  font-weight: 400;
`;
export type ScheduleModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ScheduleModal: FC<ScheduleModalProps> = ({ isOpen, onClose }) => {
  const [value, setValue] = useState("1");
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scheduled time</ModalHeader>
        <CloseButton variant={"unstyled"} onClick={onClose}>
          Done
        </CloseButton>
        <ModalBody>
          <Tips>
            Specify the day and time you want your listing to first appear to
            eBay buyers.
          </Tips>
          <RadioGroup
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            name="number"
            align={ALIGN.vertical}
          >
            <Radio value="1" overrides={styles.radio}>
              <RadioText>Start immediately</RadioText>
            </Radio>
            <Radio value="2" overrides={styles.radio}>
              <RadioText>Schedule a start time</RadioText>
            </Radio>
          </RadioGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const styles = {
  radio: {
    Root: {
      style: () => ({
        marginBottom: "32px",
      }),
    },
  },
};

export default ScheduleModal;
