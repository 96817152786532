import {
  Input as DefaultInput,
  InputGroup as DefaultInputGroup,
  InputLeftElement as DefaultInputLeftElement,
  InputRightElement as DefaultInputRightElement,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Search as SearchIcon } from "@styled-icons/evil";
import { FC } from "react";

const InputGroup = styled(DefaultInputGroup)`
  
`;

const Input = styled(DefaultInput)`
  height: 48px;
  padding-left: 50px;
  padding-right: 30px;
`;

const InputLeftElement = styled(DefaultInputLeftElement)`
  height: 48px;
  width: 48px;
`;

export type SearchProps = {
  placeholder?: string;
};

const Search: FC<SearchProps> = ({ placeholder }) => {
  return (
    <InputGroup>
      <InputLeftElement children={<SearchIcon size={32} color="#494848" />} />
      <Input placeholder={placeholder} />
    </InputGroup>
  );
};

export default Search;
