import {
  Box,
  Flex,
  List,
  ListItem as DefaultListItem,
  ListItemProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { Facebook, Twitter, Instagram } from "@styled-icons/entypo-social";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 100px;
  flex-direction: column;
  height: 400px;
  justify-content: flex-end ;
`;

const Row = styled(Flex)`
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ListItem = styled(DefaultListItem)<ListItemProps>`
  height: 50px;
  font-family: RockfordSansLight;
`;

const Footer: FC = () => {
  return (
    <Container>
      <Flex
        width="container.xl"
        alignItems={"flex-start"}
        justifyContent="flex-end"
      >
        <List width="150px">
          <ListItem fontWeight={"bold"}>Sell</ListItem>
          <ListItem>Start Selling</ListItem>
          <ListItem>Learn to Sell</ListItem>
          <ListItem></ListItem>
        </List>
        <List width="150px">
          <ListItem fontWeight={"bold"}>About</ListItem>
          <ListItem>Contact Us</ListItem>
          <ListItem>Developers</ListItem>
          <ListItem>Privacy Policy</ListItem>
          <ListItem>Terms of Use</ListItem>
        </List>
      </Flex>
      <Row width="container.xl">
        <Box>
          <a href="https://www.facebook.com">
            <Facebook size={25} />
          </a>
        </Box>
        <Box marginLeft={"16px"}>
          <a href="https://instagram.com">
            <Instagram size={25} />
          </a>
        </Box>
        <Box marginLeft={"16px"}>
          <a href="https://twitter.com">
            <Twitter size={25} />
          </a>
        </Box>
        <Flex flex={1} justifyContent="flex-end">
          <Box>Madagascar</Box>
          <Box marginLeft="16px">Terms of service</Box>
          <Box marginLeft="16px">Careers</Box>
        </Flex>
      </Row>
    </Container>
  );
};

export default Footer;
