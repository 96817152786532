import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const LIST_DATA = ['sausage'];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  search: ['searchTerm'],
  cancelSearch: null,
});

export const SearchTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  searchTerm: '',
  searching: false,
  results: LIST_DATA,
});

/* ------------- Reducers ------------- */

export const cancelSearch = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CANCEL_SEARCH]: cancelSearch,
});
