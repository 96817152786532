import {
  Button,
  Flex,
  Modal,
  ModalBody as DefaultModalBody,
  ModalCloseButton,
  ModalContent as DefaultModalContent,
  ModalHeader as DefaultModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { FC, useState } from "react";

const ModalHeader = styled(DefaultModalHeader)`
  font-family: RockfordSansMedium;
  font-weight: normal;
  text-align: center;
  margin-right: 40px;
`;

const ModalContent = styled(DefaultModalContent)`
  height: 530px;
  overflow-y: auto;
  padding: 16px 32px 0;
  padding-top: 0;
`;

const SaveButton = styled(Button)`
  height: 40px;
  width: 128px;
  font-family: RockfordSansLight;
  background-color: #4724fe;
  color: #fff;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const ModalBody = styled(DefaultModalBody)`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const MVola = styled.img`
  height: 50px;
  margin-left: 10px;
`;

export type AddPaymentOptionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AddPaymentOptionsModal: FC<AddPaymentOptionsModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [value, setValue] = useState("1");
  const onContinue = () => {
    onClose();
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Payment Options</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flex="1" marginTop={"24px"}>
            <RadioGroup
              value={value}
              onChange={(e) => setValue(e.currentTarget.value)}
              name="number"
              align={ALIGN.vertical}
            >
              <Radio value="1" overrides={styles.radio}>
                <MVola src={require("Assets/pictures/mvola.png")} />
              </Radio>
            </RadioGroup>
          </Flex>
          <Row>
            <SaveButton onClick={onContinue} rounded="full">
              Continue
            </SaveButton>
          </Row>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const styles = {
  radio: {
    RadioMarkOuter: {
      style: ({ $theme }: any) => ({
        backgroundColor: $theme.colors.accent,
      }),
    },
  },
};

export default AddPaymentOptionsModal;
