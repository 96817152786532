import {
  List,
  ListItem as DefaultListItem,
  Popover,
  PopoverContent as DefaultPopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { ChevronDown } from "@styled-icons/evaicons-solid";

const PopoverContent = styled(DefaultPopoverContent)`
  top: 10px;
  width: 280px;
  padding-top: 7px;
  padding-bottom: 7px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(156, 163, 175, 0.2) 0px 20px 25px -5px,
    rgba(156, 163, 175, 0.2) 0px 8px 10px -6px !important;
`;

const Button = styled.button`
  font-family: RockfordSansMedium;
  font-size: 16px;
  color: #4724fe;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 112px;
`;

const ListItem = styled(DefaultListItem)`
  cursor: pointer;
  font-family: RockfordSansLight;
  padding: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
  height: 49px;
  display: flex;
  align-items: center;
  &:hover {
    color: #4724fe;
    background-color: #3007ff0d;
  }
`;

export type MenuProps = {
  onSelect: (menu: string) => void;
};

const Menu: FC<MenuProps> = ({ onSelect }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const _onSelect = (menu: string) => {
    onSelect(menu);
    onClose();
  };
  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-end"
      >
        <PopoverTrigger>
          <Button>
            <span>tsirysndr</span>
            <ChevronDown size={24} style={{ marginTop: 4 }} />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <List>
            <ListItem onClick={() => _onSelect("selling")}>Selling</ListItem>
            <ListItem onClick={() => _onSelect("settings")}>Settings</ListItem>
            <ListItem onClick={() => _onSelect("logout")}>Sign out</ListItem>
          </List>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default Menu;
