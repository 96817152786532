import { useQuery } from "@apollo/client"
import { GET_USER_BY_ID } from "../GraphQL/User/queries"
import { GetUser_user, GetUser_variables } from "../GraphQL/User/types"

interface IUseUserQuery {
    getUser: GetUser_user | undefined;
    userError: Error | undefined;
    userLoading: boolean;
}
export const useUserQuery = (id?:string): IUseUserQuery => {
    const { 
        data: getUser,
        loading: userLoading,
        error: userError
    } = useQuery<GetUser_user, GetUser_variables>(GET_USER_BY_ID, {
        variables: { id : id || "" }
    });
    return {
        getUser,
        userError,
        userLoading
    }
}