import {
  Modal,
  ModalCloseButton,
  ModalContent as DefaultModalContent,
  ModalHeader as DefaultModalHeader,
  ModalOverlay,
  Input as DefaultInput,
  ModalBody,
  InputGroup as DefaultInputGroup,
  InputLeftElement,
  Flex,
  Button,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import {
  SearchOutline,
  ChevronRightOutline,
} from "@styled-icons/evaicons-outline";

const ModalHeader = styled(DefaultModalHeader)`
  font-family: RockfordSansMedium;
  font-weight: normal;
  text-align: center;
`;

const ModalContent = styled(DefaultModalContent)`
  height: 87%;
  overflow-y: auto;
`;

const Input = styled(DefaultInput)`
  border: none;
  background-color: #f9f9f9;
  padding-left: 40px;
`;

const InputGroup = styled(DefaultInputGroup)`
  margin-bottom: 24px;
`;

const Title = styled.h3`
  margin-top: 8px;
  font-family: RockfordSansMedium;
  font-size: 1.25rem;
`;

const SelectedCategory = styled.div`
  font-family: RockfordSansLight;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 40px;
`;

const Category = styled.div`
  display: flex;
  font-family: RockfordSansLight;
  height: 50px;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 18px;
  color: #4724fe;
  font-weight: 400;
`;

export type ItemCategoryModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ItemCategoryModal: FC<ItemCategoryModalProps> = ({ isOpen, onClose }) => {
  const categories = [
    "Cell Phones & Accessories",
    "Computers/Tablets & Networking",
  ];
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Item category</ModalHeader>
        <CloseButton variant={"unstyled"} onClick={onClose}>
          Done
        </CloseButton>
        <ModalBody>
          <InputGroup>
            <InputLeftElement
              color={"gray.500"}
              children={<SearchOutline size={24} />}
            />
            <Input placeholder="Enter a value" />
          </InputGroup>
          <Title>Selected</Title>
          <SelectedCategory>
            {
              "Computers/Tablets & Networking > Laptops & Netbooks > Apple Laptops"
            }
          </SelectedCategory>
          <Title>All categories</Title>
          {categories.map((category) => (
            <Category>
              <Flex flex={1}>{category}</Flex>
              <ChevronRightOutline size={28} />
            </Category>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ItemCategoryModal;
