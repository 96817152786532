import { Table } from "baseui/table-semantic";
import { FC } from "react";

const COLUMNS = ["Item", "Date", "Order #", "Status"];

const DATA = [
  ["Apple MacBook Air 13in (256GB SSD, M1, 8GB)", "lrb 06 Jol 2022 00:23:41 EAT", "FCF8F3", "Delivered"],
  ["Apple iPhone 8 Plus 64gb Black Verizon Factory", "lrb 06 Jol 2022 00:23:41 EAT", "FXSDER", "Delivered"],
  ["Apple MacBook Pro 13.3\" (1TB SSD, Intel Core i5 10th", "lrb 06 Jol 2022 00:23:41 EAT", "FGTWD", "Delivered"],
];

export type ShippingLabelsTableProps = {
}

const ShippingLabelsTable: FC<ShippingLabelsTableProps> = () => {
  return <Table columns={COLUMNS} data={DATA} />;
}

export default ShippingLabelsTable;
