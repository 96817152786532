import styled from "@emotion/styled";
import { FC } from "react";

const Container = styled.div`
  font-size: 1.25rem;
  height: 100%;
  display: flex;
  padding-top: 20%;
  align-items: center;
  font-family: RockfordSansMedium;
  flex-direction: column ;
`;

export type PlaceholderProps = {
  text: string;
  children?: React.ReactNode;
};

const Placehorder: FC<PlaceholderProps> = ({ text, children }) => {
  return (
    <Container>
      {text}
      {children}
    </Container>
  );
};

export default Placehorder;
