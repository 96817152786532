import { FC } from "react";
import Header from "Components/Header";
import LeftNavigation from "Components/LeftNavigation";
import styled from "@emotion/styled";
import SummaryTitle from "Components/SummaryTitle";
import Placehorder from "Components/Placeholder";
import { useNavigate } from "react-router-dom";
import ActiveListings from "./ActiveListings";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const ActiveContainer = styled.div`
  width: 880px;
`;

const Link = styled.a`
  color: #4724fe;
  font-family: RockfordSansRegular;
  font-weight: normal;
  font-size: 18px;
  margin-top: 10px;
`;

const RightPane = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const Active: FC = () => {
  const navigate = useNavigate();
  const onSearch = (query: string) => {
    navigate(`/search?q=${query}`);
  };
  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <LeftNavigation active="active" />
        <ActiveContainer>
          <SummaryTitle text="Active" />
          {false && (
            <Placehorder text="You don't have any active items.">
              <Link href="/sell">Start a listing</Link>
            </Placehorder>
          )}
          {
            true && <ActiveListings />
          }
        </ActiveContainer>
        <RightPane />
      </Content>
    </div>
  );
};

export default Active;
