import { FC } from "react";
import Header from "Components/Header";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";
import { CloseOutline } from "@styled-icons/evaicons-outline";
import { Box } from "@chakra-ui/react";
import Filter from "Components/Filter";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import OrderPlaceholder from "Components/OrderPlaceholder";

const Content = styled.div`
  height: calc(100vh - 180px);
  display: flex;
  padding-top: 100px;
  margin: 0 auto;
  width: 1200px;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
`;

const Filters = styled(Row)`
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 25px;
`;

const Clear = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: rgb(106, 115, 131);
  &:hover {
    color: #000;
  }
`;

const HeaderTitle = styled.div`
  font-family: RockfordSansMedium;
  font-weight: 500;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  flex-direction: column;
`;

const PlaceholderTitle = styled.div`
  font-family: RockfordSansMedium;
  font-size: 21px;
  color: #000;
  margin-bottom: 10px;
  margin-top: 15px;
`;

const PlaceholderSubtitle = styled.div`
  font-family: RockfordSansRegular;
  font-size: 18px;
  color: #5a5959;
  text-align: center;
`;

const TableHeaderTitle: FC<{ title: string }> = ({ title }) => {
  return <HeaderTitle>{title}</HeaderTitle>;
};

export type Order = {
  id: string;
  orderId: string;
  amount: string;
  destination: string;
  purchaseDate: string;
  status: string;
};

export type OrdersProps = {
  orders?: Order[];
};

const Orders: FC<OrdersProps> = ({ orders }) => {

  const navigate = useNavigate();
  const onSearch = (query: string) => {
    if (query === "") {
      navigate("/categories");
      return;
    }
    navigate(`/search?q=${query}`);
  };
  return (
    <div>
      <Header onSearch={onSearch} />
      <Content>
        <Title>Orders</Title>
        {orders!.length > 0 && (
          <>
            <Filters>
              <Row>
                <Filter
                  date
                  label="Date"
                  onApply={() => {}}
                  options={[
                    "is in the last",
                    "is equal to",
                    "is between",
                    "is after",
                    "is before",
                  ]}
                />
                <Filter
                  label="Amount"
                  onApply={() => {}}
                  options={[
                    "is equal to",
                    "is between",
                    "is greater than",
                    "is less than",
                  ]}
                />
                <Filter
                  label="Status"
                  onApply={() => {}}
                  options={["Payment Received"]}
                  multi
                />
              </Row>
              <Clear>
                <IconWrapper>
                  <CloseOutline size={20} />
                </IconWrapper>
                <Box fontSize={14} marginTop={"-2px"}>
                  Clear Filters
                </Box>
              </Clear>
            </Filters>

            <TableBuilder data={orders!}>
              <TableBuilderColumn
                header={<TableHeaderTitle title="Order Number" />}
              >
                {(row) => (
                  <div>
                    <Link to={`/orders/${row.orderId}`}>{row.orderId}</Link>
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header={<TableHeaderTitle title="Purchase Number" />}
              >
                {(row) => <div>{row.purchaseDate}</div>}
              </TableBuilderColumn>
              <TableBuilderColumn header={<TableHeaderTitle title="Amount" />}>
                {(row) => <div>{row.amount}</div>}
              </TableBuilderColumn>
              <TableBuilderColumn
                header={<TableHeaderTitle title="Destination" />}
              >
                {(row) => <div>{row.destination}</div>}
              </TableBuilderColumn>
              <TableBuilderColumn header={<TableHeaderTitle title="Status" />}>
                {(row) => <div>{row.status}</div>}
              </TableBuilderColumn>
            </TableBuilder>
          </>
        )}
        {orders!.length === 0 && (
          <Placeholder>
            <OrderPlaceholder />
            <PlaceholderTitle>No orders yet</PlaceholderTitle>
            <PlaceholderSubtitle>
              When you place your first order, it will appear here
            </PlaceholderSubtitle>
          </Placeholder>
        )}
      </Content>
    </div>
  );
};

Orders.defaultProps = {
  orders: [],
};

export default Orders;
