import { Button, Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC, useRef } from "react";
import {
  AddPhotoAlternate,
  VideoLibrary,
} from "@styled-icons/material-outlined";
import Placeholder from "./Placeholder";

const Container = styled.div`
  padding: 25px;
  background-color: #fff;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 20px;
`;

const ImageWrapper = styled.div`
  height: 310px;
  width: 310px;
  margin-right: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e6e6;

  border-radius: 8px;
  &:hover {
    border: 1px solid #4724fe;
  }
`;

const Image = styled.img`
  max-width: 305px;
`;

const UploaderThumbnails = styled.div`
  display: flex;
  flex-direction: row;
  height: 320px;
  margin-top: 24px;
`;

const AddPhotos = styled(Button)`
  height: 152px;
  width: 152px;
  background-color: #f7f7f7;
  margin-right: 8px;
  font-family: RockfordSansLight;
  font-weight: normal;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #f7f7f7;
    border: 1px solid #4724fe;
  }
`;

const Icon = styled.div`
  background-color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 240px;
`;

const PhotoInput = styled.input`
  display: none;
`;

const VideoInput = styled.input`
  display: none;
`;

const ButtonLabel = styled.span`
  margin-top: 10px;
`;

const AddVideos = styled(AddPhotos)``;

export type ProductInfoProps = {};

const ProductInfo: FC<ProductInfoProps> = () => {
  const photoInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const onSelectPhoto = () => photoInputRef.current!.click();
  const onSelectVideo = () => videoInputRef.current!.click();
  const showPlacehoder = false;
  return (
    <Container>
      <Title>PHOTOS & VIDEO</Title>
      {!showPlacehoder && (
        <UploaderThumbnails>
          <ImageWrapper>
            <Image src={require("Assets/pictures/s-l640 (5).jpg")} />
          </ImageWrapper>
          <Flex flex={1}>
            <AddPhotos onClick={onSelectPhoto}>
              <Icon>
                <AddPhotoAlternate size={24} />
              </Icon>
              <ButtonLabel>Add photos</ButtonLabel>
            </AddPhotos>
            <AddVideos onClick={onSelectVideo}>
              <Icon>
                <VideoLibrary size={24} />
              </Icon>
              <ButtonLabel>Add video</ButtonLabel>
            </AddVideos>
          </Flex>
          <PhotoInput type="file" ref={photoInputRef} accept="image/*" />
          <VideoInput type="file" ref={videoInputRef} accept="video/*" />
        </UploaderThumbnails>
      )}
      {showPlacehoder && (
        <FilePickerWrapper>
          <Placeholder
            onSelectPhoto={onSelectPhoto}
            onSelectVideo={onSelectVideo}
          />
        </FilePickerWrapper>
      )}
    </Container>
  );
};

export default ProductInfo;
