import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const LIST_DATA = [{id: 1, name: 'sausage'}];

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  addPromotion: ['promotion'],
  getPromotion: ['id'],
  getAllPromotions: null,
  promotionsReceived: ['promotions'],
  promotionReceived: ['promotion'],
});

export const PromotionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  recentListings: LIST_DATA,
});

/* ------------- Reducers ------------- */

export const promotionReceived = () => INITIAL_STATE;

export const promotionsReceived = () => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PROMOTION_RECEIVED]: promotionReceived,
  [Types.PROMOTIONS_RECEIVED]: promotionsReceived,
});
