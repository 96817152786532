import {
  Button,
  Modal,
  ModalBody as DefaultModalBody,
  ModalContent as DefaultModalContent,
  ModalHeader as DefaultModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { FC, useState } from "react";

const ModalHeader = styled(DefaultModalHeader)`
  font-family: RockfordSansMedium;
  font-weight: normal;
  text-align: center;
  margin-right: 40px;
`;

const ModalContent = styled(DefaultModalContent)`
  height: 87%;
  overflow-y: auto;
  padding: 16px 32px 0;
  padding-top: 0;
`;

const ModalBody = styled(DefaultModalBody)`
  padding: 0;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 18px;
  color: #4724fe;
  font-weight: 400;
`;

const Tips = styled.div<{ marginBottom?: string }>`
  font-family: RockfordSansLight;
  font-size: 15px;
  color: gray;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `};
`;

const Text = styled.div<{ color?: string }>`
  font-family: RockfordSansLight;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

const RadioText = styled(Text)``;

export type HandlingTimeModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const HandlingTimeModal: FC<HandlingTimeModalProps> = ({ isOpen, onClose }) => {
  const [value, setValue] = useState("1");
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Handling time</ModalHeader>
        <CloseButton variant={"unstyled"} onClick={onClose}>
          Done
        </CloseButton>
        <ModalBody>
          <Tips marginBottom="32px">
            Handling time is the number of business days between when you
            receive payment for an item and when that package is scanned by your
            shipping carrier.
          </Tips>
          <RadioGroup
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            name="number"
            align={ALIGN.vertical}
          >
            <Radio value="1" overrides={styles.radio}>
              <RadioText>Same business day</RadioText>
            </Radio>
            <Radio value="2" overrides={styles.radio}>
              <RadioText>1 business day</RadioText>
            </Radio>
            <Radio value="3" overrides={styles.radio}>
              <RadioText>2 business days</RadioText>
            </Radio>
            <Radio value="4" overrides={styles.radio}>
              <RadioText>3 business days</RadioText>
            </Radio>
            <Radio value="5" overrides={styles.radio}>
              <RadioText>5 business days</RadioText>
            </Radio>
            <Radio value="6" overrides={styles.radio}>
              <RadioText>6 business days</RadioText>
            </Radio>
            <Radio value="7" overrides={styles.radio}>
              <RadioText>7 business days</RadioText>
            </Radio>
            <Radio value="8" overrides={styles.radio}>
              <RadioText>10 business days</RadioText>
            </Radio>
            <Radio value="9" overrides={styles.radio}>
              <RadioText>15 business days</RadioText>
            </Radio>
            <Radio value="10" overrides={styles.radio}>
              <RadioText>20 business days</RadioText>
            </Radio>
            <Radio value="11" overrides={styles.radio}>
              <RadioText>30 business days</RadioText>
            </Radio>
            <Radio value="21" overrides={styles.radio}>
              <RadioText>40 business days</RadioText>
            </Radio>
          </RadioGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const styles = {
  radio: {
    Root: {
      style: () => ({
        marginBottom: "20px",
      }),
    },
  },
};

export default HandlingTimeModal;
