import { FC } from "react";

const OrderPlaceholder: FC = (props) => (
  <svg height={250} width={250} viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg" {...props}>
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1={-9.013}
      x2={466.02}
      y1={152.996}
      y2={381.285}
    >
      <stop offset={0} stopColor="#c6d1ff" />
      <stop offset={0.583} stopColor="#e6d5f4" />
      <stop offset={0.997} stopColor="#ffd9ec" />
    </linearGradient>
    <path
      d="M373.553 146.774h-.003V68.34c0-5.52-4.47-10-10-10h-215.1c-5.53 0-10 4.48-10 10v407.77c0 3.73 3.94 6.15 7.27 4.45l13.97-7.13a5 5 0 0 1 4.55 0l18.96 9.68a5 5 0 0 0 4.55 0l18.97-9.68a4.98 4.98 0 0 1 4.54 0l18.97 9.68c1.43.73 3.12.73 4.54 0l18.97-9.68a5 5 0 0 1 4.55 0l18.96 9.68a5 5 0 0 0 4.55 0l18.96-9.68a4.98 4.98 0 0 1 4.54 0l18.97 9.68c1.42.73 3.11.73 4.54 0l18.96-9.68a5 5 0 0 1 4.55 0l13.96 7.13c3.33 1.7 7.27-.72 7.27-4.46V253.312h.003c29.42 0 53.269-23.85 53.269-53.269 0-29.42-23.849-53.269-53.269-53.269z"
      fill="#2626bc"
      opacity={0.1}
    />
    <path
      d="M471.476 133.315c-65.417-50.293-128.867-9.306-199.64-23.797-71.285-14.597-99.685-63.201-180.876-40.766-71.922 19.874-115.562 99.552-76.024 166.259 11.267 19.009 30.461 33.35 38.305 54.619 12.653 34.311-6.607 60.653-2.85 94.372 15.612 140.102 229.574 85.834 308.303 42.245 123.47-68.36 206.998-220.497 112.782-292.932z"
      fill="url(#a)"
    />
    <path
      d="M373.55 43.34V451.1c0 3.74-3.94 6.16-7.27 4.46l-13.96-7.13a5 5 0 0 0-4.55 0l-18.96 9.68a4.98 4.98 0 0 1-4.54 0l-18.97-9.68a4.98 4.98 0 0 0-4.54 0l-18.96 9.68a5 5 0 0 1-4.55 0l-18.96-9.68a5 5 0 0 0-4.55 0l-18.97 9.68a4.98 4.98 0 0 1-4.54 0l-18.97-9.68a4.98 4.98 0 0 0-4.54 0l-18.97 9.68a5 5 0 0 1-4.55 0l-18.96-9.68a5 5 0 0 0-4.55 0l-13.97 7.13c-3.33 1.7-7.27-.72-7.27-4.45V43.34c0-5.52 4.47-10 10-10h215.1c5.53 0 10 4.48 10 10z"
      fill="#fff"
    />
    <path
      d="M373.55 144.6v106.54c-29.42 0-53.27-23.85-53.27-53.27s23.85-53.27 53.27-53.27z"
      fill="#dcfdee"
    />
    <path
      d="M279.523 463.653a9.999 9.999 0 0 1-4.545-1.089l-18.965-9.68-18.965 9.68a10.034 10.034 0 0 1-9.091 0l-18.966-9.68-18.966 9.68a10.031 10.031 0 0 1-9.091 0l-18.97-9.68-13.97 7.129c-3.125 1.595-6.776 1.453-9.768-.379s-4.778-5.02-4.778-8.528V43.343c0-8.271 6.729-15 15-15h48.973a5 5 0 0 1 0 10h-48.973c-2.757 0-5 2.243-5 5v407.762l13.969-7.129a10.031 10.031 0 0 1 9.091 0l18.97 9.68 18.966-9.68a10.026 10.026 0 0 1 9.093 0l18.964 9.679 18.965-9.68a10.026 10.026 0 0 1 9.093 0l18.964 9.679 18.961-9.679a10.029 10.029 0 0 1 9.093 0l18.962 9.68 18.958-9.679a10.03 10.03 0 0 1 9.092-.001l13.963 7.127V257.906a5 5 0 0 1 10 0v193.198a9.93 9.93 0 0 1-4.778 8.528 9.928 9.928 0 0 1-9.768.378l-13.962-7.127-18.959 9.679a10.022 10.022 0 0 1-9.094 0l-18.96-9.679-18.961 9.679a9.99 9.99 0 0 1-4.547 1.091z"
      fill="#2626bc"
    />
    <path
      d="M261 217.904h-86.52a5 5 0 1 1 0-10H261a5 5 0 1 1 0 10z"
      fill="#8399fe"
    />
    <path
      d="M186 197.873h-11.52a5 5 0 1 1 0-10H186a5 5 0 1 1 0 10z"
      fill="#01eca5"
    />
    <path
      d="M281 263.312H174.48a5 5 0 1 1 0-10H281a5 5 0 1 1 0 10z"
      fill="#8399fe"
    />
    <path
      d="M186 243.28h-11.52a5 5 0 1 1 0-10H186a5 5 0 1 1 0 10z"
      fill="#01eca5"
    />
    <path
      d="M271 308.719h-96.52a5 5 0 1 1 0-10H271a5 5 0 1 1 0 10z"
      fill="#8399fe"
    />
    <path
      d="M186 288.687h-11.52a5 5 0 1 1 0-10H186a5 5 0 1 1 0 10z"
      fill="#01eca5"
    />
    <path
      d="M231 354.127h-56.52a5 5 0 1 1 0-10H231a5 5 0 1 1 0 10zM276.76 354.127h-21.52a5 5 0 1 1 0-10h21.52a5 5 0 1 1 0 10z"
      fill="#8399fe"
    />
    <path
      d="M186 334.095h-11.52a5 5 0 1 1 0-10H186a5 5 0 1 1 0 10z"
      fill="#01eca5"
    />
    <path
      d="M334.616 217.904h-16.52a5 5 0 1 1 0-10h16.52a5 5 0 1 1 0 10z"
      fill="#8399fe"
    />
    <path
      d="M339.62 212.9c0 2.77-2.24 5-5 5h-10.45a53.36 53.36 0 0 1-2.95-10h13.4c2.76 0 5 2.24 5 5z"
      fill="#6583fe"
    />
    <path
      d="M334.616 263.312h-16.52a5 5 0 1 1 0-10h16.52a5 5 0 1 1 0 10zM334.616 308.719h-16.52a5 5 0 1 1 0-10h16.52a5 5 0 1 1 0 10zM334.616 354.127h-16.52a5 5 0 1 1 0-10h16.52a5 5 0 1 1 0 10z"
      fill="#8399fe"
    />
    <path
      d="M332.961 334.152a5 5 0 0 1-5-5v-.115a5 5 0 0 1 10 0v.115a5 5 0 0 1-5 5z"
      fill="#01eca5"
    />
    <path
      d="M334.616 412h-24.14a5 5 0 1 1 0-10h24.14a5 5 0 1 1 0 10zM208.621 412h-34.14a5 5 0 1 1 0-10h34.14a5 5 0 1 1 0 10z"
      fill="#8399fe"
    />
    <path
      d="M332.961 288.745a5 5 0 0 1-5-5v-.115a5 5 0 0 1 10 0v.115a5 5 0 0 1-5 5zM332.961 243.338a5 5 0 0 1-5-5v-.115a5 5 0 0 1 10 0v.115a5 5 0 0 1-5 5zM332.961 197.93a5 5 0 0 1-5-5v-.115a5 5 0 0 1 10 0v.115a5 5 0 0 1-5 5z"
      fill="#01eca5"
    />
    <circle cx={373.553} cy={175.043} fill="#01eca5" r={53.269} />
    <path
      d="M378.553 117.003v-73.66c0-8.271-6.729-15-15-15h-136.88a5 5 0 0 0 0 10h136.88c2.757 0 5 2.243 5 5v73.66c-29.796 2.547-53.269 27.596-53.269 58.041 0 32.13 26.14 58.269 58.27 58.269s58.269-26.139 58.269-58.269c-.001-30.445-23.474-55.496-53.27-58.041zm-5 106.309c-26.616 0-48.27-21.653-48.27-48.269s21.654-48.27 48.27-48.27 48.269 21.654 48.269 48.27-21.653 48.269-48.269 48.269z"
      fill="#2626bc"
    />
    <path
      d="M380.069 170.043h-1.516v-10.18h9.454a5 5 0 0 0 0-10h-9.454v-2.514a5 5 0 0 0-10 0v2.514h-1.516c-8.321 0-15.09 6.769-15.09 15.09s6.769 15.09 15.09 15.09h1.516v10.18H359.1a5 5 0 0 0 0 10h9.453v2.514a5 5 0 0 0 10 0v-2.514h1.516c8.32 0 15.089-6.769 15.089-15.09s-6.769-15.09-15.089-15.09zm-13.032 0c-2.807 0-5.09-2.283-5.09-5.09s2.283-5.09 5.09-5.09h1.516v10.18zm13.032 20.18h-1.516v-10.18h1.516c2.806 0 5.089 2.283 5.089 5.09s-2.283 5.09-5.089 5.09z"
      fill="#fff"
    />
    <path
      d="M276.76 158.719h-41.52a5 5 0 1 1 0-10h41.52a5 5 0 1 1 0 10z"
      fill="#8399fe"
    />
    <path
      d="M304.26 121.774h-96.52a5 5 0 1 1 0-10h96.52a5 5 0 1 1 0 10zM329.26 99.828H182.74a5 5 0 1 1 0-10h146.52a5 5 0 1 1 0 10z"
      fill="#4369fd"
    />
    <path
      d="M186 77.883h-11.52a5 5 0 1 1 0-10H186a5 5 0 1 1 0 10z"
      fill="#01eca5"
    />
    <path
      d="M411.18 82.376a14.458 14.458 0 0 1-10.253-4.24c-5.653-5.653-5.653-14.852 0-20.506s14.853-5.654 20.506 0c5.653 5.654 5.653 14.853 0 20.506a14.455 14.455 0 0 1-10.253 4.24zm.001-18.991a4.482 4.482 0 0 0-3.182 1.316 4.506 4.506 0 0 0 0 6.364 4.505 4.505 0 0 0 6.364 0 4.506 4.506 0 0 0 0-6.364 4.485 4.485 0 0 0-3.182-1.316z"
      fill="#00eca2"
    />
    <path
      d="M436.322 131.267a14.454 14.454 0 0 1-10.253-4.24c-2.739-2.739-4.247-6.38-4.247-10.253s1.508-7.514 4.248-10.253c5.652-5.653 14.851-5.654 20.505 0a14.404 14.404 0 0 1 4.247 10.253c0 3.874-1.508 7.515-4.248 10.253a14.453 14.453 0 0 1-10.252 4.24zm0-18.991a4.483 4.483 0 0 0-3.182 1.316c-.851.85-1.318 1.98-1.318 3.182s.468 2.332 1.318 3.182a4.504 4.504 0 0 0 6.364 0c.851-.85 1.318-1.98 1.318-3.182s-.468-2.332-1.318-3.182a4.482 4.482 0 0 0-3.182-1.316zM105.379 236.759a14.457 14.457 0 0 1-10.253-4.24c-5.653-5.654-5.653-14.853 0-20.506 5.653-5.652 14.853-5.653 20.506 0 5.653 5.654 5.653 14.853 0 20.506a14.457 14.457 0 0 1-10.253 4.24zm0-18.991a4.483 4.483 0 0 0-3.182 1.316 4.504 4.504 0 0 0 0 6.364 4.505 4.505 0 0 0 6.364 0 4.504 4.504 0 0 0 0-6.364 4.482 4.482 0 0 0-3.182-1.316z"
      fill="#ff7eb8"
    />
    <path
      d="M98.933 281.7a4.987 4.987 0 0 1-3.536-1.464l-5.964-5.964-5.964 5.964a5.001 5.001 0 0 1-7.071-7.071l9.5-9.5a5.001 5.001 0 0 1 7.071 0l9.5 9.5a5 5 0 0 1-3.536 8.535z"
      fill="#6583fe"
    />
    <path
      d="M426.822 426.5a4.987 4.987 0 0 1-3.536-1.464l-5.964-5.964-5.964 5.964a5.001 5.001 0 0 1-7.071-7.071l9.5-9.5a5.001 5.001 0 0 1 7.071 0l9.5 9.5a5 5 0 0 1-3.536 8.535z"
      fill="#00eca2"
    />
  </svg>
);

export default OrderPlaceholder;
