import { Box, Flex, List as DefaultList, ListItem } from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { CheckmarkOutline } from "@styled-icons/evaicons-outline";
import { HashLink } from "react-router-hash-link";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 180px);
  justify-content: flex-end;
`;

const List = styled(DefaultList)`
  margin-right: 25px;
`;

const Link = styled(HashLink)<{ isActive?: boolean }>`
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
  width: 226px;
  ${({ isActive }) =>
    isActive &&
    css`
      color: #4724fe;
    `}
  &:hover {
    ${({ isActive }) =>
      isActive &&
      css`
        color: #4724fe !important;
      `}
  }
`;

const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 23px;
  color: #000;
  padding-left: 15px;
  padding-right: 15px;
  font-family: RockfordSansMedium;
`;

const Row = styled(Flex)`
  flex: row;
  align-items: center;
`;

export type CategoryNavigationProps = {
  active: string;
  onSelect: (category: string) => void;
  categories: any[];
};

const CategoryNavigation: FC<CategoryNavigationProps> = ({
  active,
  onSelect,
  categories,
}) => {
  return (
    <Container>
      <Box>
        <Title>All Categories</Title>
        <List spacing={5} width={250}>
          {categories.map((category) => (
            <ListItem key={category.id}>
              <Row>
                <Flex flex={1}>
                  <Link
                    to={`/categories#${category.slug}`}
                    isActive={active === category.slug}
                    onClick={() => onSelect(category.slug)}
                  >
                    {category.name}
                  </Link>
                </Flex>
                {active === category.slug && (
                  <CheckmarkOutline size={24} color="#4724fe" />
                )}
              </Row>
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default CategoryNavigation;
