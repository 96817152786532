import {
  Box,
  ButtonProps,
  List,
  ListItem as DefaultListItem,
  Popover,
  PopoverArrow,
  PopoverContent as DefaultPopoverContent,
  PopoverHeader as DefaultPopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";
import { ShoppingCartOutline } from "@styled-icons/evaicons-outline";
import Button from "Components/Button";
import { useNavigate } from "react-router-dom";
import CartPlaceholder from "Components/CartPlaceholder";
import Cart from "./Cart";

const ShoppingCartButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
`;

const PopoverContent = styled(DefaultPopoverContent)`
  top: 10px;
  min-height: 200px;
  width: 350px;
  padding-bottom: 7px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(156, 163, 175, 0.2) 0px 20px 25px -5px,
    rgba(156, 163, 175, 0.2) 0px 8px 10px -6px !important;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  font-size: 18px;
  flex-direction: column;
  padding: 10px;
`;

const Text = styled.p`
  font-family: RockfordSansLight;
  font-size: 15px;
  text-align: center;
`;

const Title = styled.h1`
  font-family: RockfordSansMedium;
  font-size: 18px;
`;

const PopoverHeader = styled(DefaultPopoverHeader)`
  border-bottom: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 52px;
`;

const OrdersButton = styled(Button)<ButtonProps>`
  height: 35px;
  width: 80px;
`;

const Badge = styled.span`
  background-color: #fe246d;
  color: #fff;
  border-radius: 50%;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  top: -5px;
  right: -10px;
  font-size: 10px;
  font-family: RockfordSansBold;
  min-width: 20px;
`;

export type ShoppingCartProps = {
  counter: number;
  currentCart: any;
  shops: any;
  quantityTotal: any;
  subtotal: any;
  carts?: any[];
};

const ShoppingCarts: FC<ShoppingCartProps> = ({
  counter,
  currentCart,
  subtotal,
  shops,
  quantityTotal,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigation = useNavigate();

  return (
    <ShoppingCartButtonWrapper>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <button>
            <Box position={"relative"}>
              {counter > 0 && <Badge>{counter}</Badge>}
              <ShoppingCartOutline size={28} />
            </Box>
          </button>
        </PopoverTrigger>
        <PopoverContent onMouseLeave={onClose}>
          <PopoverArrow />
          <PopoverHeader>
            <Title>Carts</Title>
            <OrdersButton
              label="Orders"
              rounded="full"
              onClick={() => {
                navigation("/orders");
              }}
            />
          </PopoverHeader>
          {counter === 0 && (
            <Placeholder>
              <CartPlaceholder height={100} />
              <Box marginTop={"10px"}>Add items to start a cart</Box>
              <Text>
                once you add items from a store, your cart will appear here
              </Text>
            </Placeholder>
          )}
          <List>
            {counter > 0 &&
              Object.keys(currentCart).map((cartId) => (
                <Cart
                  item={cartId}
                  shops={shops}
                  subtotal={subtotal}
                  quantityTotal={quantityTotal}
                  currentCart={currentCart}
                  onClose={onClose}
                />
              ))}
          </List>
        </PopoverContent>
      </Popover>
    </ShoppingCartButtonWrapper>
  );
};

export default ShoppingCarts;
