import { gql } from "@apollo/client";
import { USER_INFO_FRAGMENT } from "../../User/fragments";

export const PRODUCT_BASIC_INFO_FRAGMENT = gql`
    ${USER_INFO_FRAGMENT}
    fragment ProductBasicInfo on Product {
        id
        name
        price
        description
        image
        category
        condition
        quantityAvailable
        quantitySold
        numberOfWatchers
        createdAt
        updatedAt
        seller {
            ...UserInformation
        }
    }`