import {
  Popover as DefaultPopover,
  PopoverAnchor,
  PopoverContent as DefaultPopoverContent,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FC } from "react";

const Popover = styled(DefaultPopover)``;

const PopoverContent = styled(DefaultPopoverContent)`
  width: 1052px;
  min-height: 50px;
  padding: 15px;
  top: -8px;
  border-top: none;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
`;

export type SuggestionsProps = {
  isOpen: boolean;
  results: any[];
  children?: any;
};

const Suggestions: FC<SuggestionsProps> = ({ isOpen, results, children }) => {
  return (
    <Popover isOpen={isOpen} placement="auto-start">
      <PopoverAnchor>{children}</PopoverAnchor>
      <PopoverContent>test</PopoverContent>
    </Popover>
  );
};

export default Suggestions;
