import { graphql } from "msw";

const users = [
  {
    ___typename: "User",
    id: "GPbYPlbcxc1E3r1b",
    name: "AlexQueen",
    stars: 5,
    positiveFeedback: 50,
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    ___typename: "User",
    id: "9qy4TK0Ju4jTY4zb",
    name: "TsirySndr",
    stars: 5,
    positiveFeedback: 75,
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
  {
    ___typename: "User",
    id: "qZ56yKFdt1vHKPHm",
    name: "Kantsniat",
    stars: 5,
    positiveFeedback: 75,
    createdAt: "2020-01-01T00:00:00.000Z",
    updatedAt: "2020-01-01T00:00:00.000Z",
  },
];

const userHandler = [
  graphql.query("GetUserById", (req, res, ctx) => {
    const { id } = req.variables;
    return res(ctx.data({ user: users.find((user) => user.id === id) }));
  }),
];

export default userHandler;
